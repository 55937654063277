import React, { Fragment } from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import numbro from 'numbro';
import { useQuery } from '@apollo/react-hooks';

import LinearProgress from '@material-ui/core/LinearProgress';

import useGenericDashboardStatistics from '../../../../hooks/useGenericDashboardStatistics';

import styles from './TerritoryClassPercentageInfo.module.scss';

import {
  GET_CLASS_TREE_DATA,
} from './query';

const formatNumber = (value, mantissa = 0) => {
  if (value === null || value === undefined) {
    return '-';
  } else if (value === 0) {
    return 0;
  } else {
    return numbro(value).format({
      thousandSeparated: true,
      mantissa
    });
  }
};

export default function TerritoryClassPercentageInfo({
  className,
  activeYear,
  classTreeKey,
  territoryId,
  statisticsPath,
  additionalParams = {},
  pixelValue,
  color,
  additionalText,
}) {
  const statisticsData = useGenericDashboardStatistics({
    classTreeKey,
    fromYear: activeYear,
    toYear: activeYear,
    territoryId,
    statisticsPath,
    ...additionalParams,
  }, null);
  const { loading, data } = statisticsData;

  const { data: classTreeData } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey
    },
  });

  const classesLevelsList = _.get(classTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi');
  const areas = _.get(data, '[0].areas');
  const pixelValueLevel = _.get(_.find(classesLevelsList, { pixelValue }), 'level');
  const pixelValueLevelIds = _.map(_.filter(classesLevelsList, { level: pixelValueLevel }), 'pixelValue');
  const pixelValueLevelTotal = _.reduce(pixelValueLevelIds, (sum, pixelValue) => {
    return sum + _.get(_.find(areas, { pixelId: pixelValue }), 'value', 0);
  }, 0);
  const pixelValueData = _.get(_.find(_.get(data, '[0].areas'), { pixelId: pixelValue }), 'value');
  const pixelValuePercentage = (pixelValueData / pixelValueLevelTotal) * 100;

  const renderInfo = () => {
    if (!pixelValueData) {
      return null;
    }

    const percentageLabel = formatNumber(pixelValuePercentage, 1) + '%';

    return (
      <div className={ classnames(className) }>
        <div className={ styles.bar }>
          <span className={ styles.backgroundBar } />
          <span
            className={ styles.mainBar }
            style={{ width: `${ pixelValuePercentage }%`, backgroundColor: color }}
          />
        </div>
        <div className={ styles.infoContent }>
          <span className={ styles.infoContentValue } style={{ color }}>{ percentageLabel }</span>
          <span className={ styles.infoContentText }>{ additionalText }</span>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      { loading &&
        <div className={ styles.progressWrapper }>
          <LinearProgress />
        </div>
      }
      { !loading && renderInfo() }
    </Fragment>
  );
}
