import React, { useState } from 'react';
import classnames from 'classnames';
import _ from 'lodash';

import { useIntl, FormattedMessage } from 'react-intl';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import AnnualStatisticsContent from './content/AnnualStatisticsContent';
import AccumulatedStatisticsContent from './content/AccumulatedStatisticsContent';
import AccumulatedDistributedStatisticsContent from './content/AccumulatedDistributedStatisticsContent';
import CoverageMainStatisticsContent from './content/CoverageMainStatisticsContent';
import CoverageMainStatisticsContentCustom from './content/CoverageMainStatisticsContentCustom';
import FireAnnualTotalBurnedStatisticsContent from './content/FireAnnualTotalBurnedStatisticsContent';
import FireMonthlyStatisticsContent from './content/FireMonthlyStatisticsContent';
import FireAccumulatedStatisticsContent from './content/FireAccumulatedStatisticsContent';
import FrequencyStatisticsContent from './content/FrequencyStatisticsContent';
import FrequencyByYearStatisticsContent from './content/FrequencyByYearStatisticsContent';
import NumberOfClassesStatisticsContent from './content/NumberOfClassesStatisticsContent';
import DegradationFragmentationEdgeArea from './content/DegradationFragmentationEdgeArea';
import DegradationMultipleContent from './content/DegradationMultipleContent';
import UrbanCrossingStatisticsContent from './content/UrbanCrossingStatisticsContent';
import GEESimpleStatisticsContent from './content/GEESimpleStatisticsContent';

import InfoTooltip from '../../../../../../components/InfoTooltip';

import styles from './StatisticsBox.module.scss';

export default function StatisticsBox({
  activeModule,
  activeModuleContent,
  activeYear,
  baseParams,
  classTreeKey,
  statisticsDataIsVisible,
  onShowData = () => {},
  headerIsVisible,
  ruralPropertyCode,
  geometry,
}) {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const degradationType = _.get(baseParams, 'degradationType');
  const customViewMode = _.get(baseParams, 'customViewMode');
  const isOnDegradationCrossingMode = degradationType === 'crossing';

  const intl = useIntl()

  const mapClassTreeKeyToTooltip = {
    'fire_annual_total_burned': intl.formatMessage({id: 'mapbiomas.statistics.burned.annual_total.charts.tooltip'}),
    'fire_monthly': intl.formatMessage({id: 'mapbiomas.statistics.burned.monthly.charts.tooltip'}),
    'fire_frequency': intl.formatMessage({id: 'mapbiomas.statistics.burned.frequency.charts.tooltip'}),
    'fire_accumulated': intl.formatMessage({id: 'mapbiomas.statistics.burned.accumulated.charts.tooltip'}),
  };

  const mapModuleKeyToCustomVersion = {
    'degradation': '8.0',
    'sentinel': '8.0',
    'fire': '8.0',
  };

  const defaultContentParams = {
    activeYear,
    baseParams,
    classTreeKey,
    statisticsDataIsVisible,
    onShowData,
    clientType: mapModuleKeyToCustomVersion[activeModule],
    ruralPropertyCode,
    geometry,
  };
  const titleTooltip = mapClassTreeKeyToTooltip[classTreeKey];

  const [isVisible, setIsVisible] = useState(true);

  const toggleVisibility = () => setIsVisible(!isVisible);

  let CoverageMainStatisticsComponent = CoverageMainStatisticsContent;

  if (baseParams.crossingEnabled || ruralPropertyCode || geometry) {
    CoverageMainStatisticsComponent = CoverageMainStatisticsContentCustom;
  }

  return (
    <div id="statistics" className={ classnames(styles.wrapper, {
      [styles.wrapperWithHeader]: headerIsVisible,
      [styles.wrapperIsHidden]: isMobile,
    }) }>
      <header className={ styles.header }>
        <h2 className={ styles.title }>
          <span><FormattedMessage id="mapbiomas.statistics.title" /></span>
          { titleTooltip && <InfoTooltip text={ titleTooltip } /> }
        </h2>
        <IconButton
          className={ classnames(styles.closeButton, {
            [styles.closeButtonCollapse]: isVisible
          }) }
          onClick={ toggleVisibility }
          id="statistics-toggle"
        >
          <ExpandLessIcon />
        </IconButton>
      </header>
      <Collapse id="statistics-graphics" in={ isVisible } timeout={ 500 } className={ styles.collapseWrapper }>
        <div className={ styles.content }>
          { activeModuleContent === 'sentinel:sentinel_coverage' &&
            <div className={ styles.fireModeContentBoxExtra }>
              <h2 className={ styles.fireModeContentBoxTitle }><FormattedMessage id="mapbiomas.warning.sentinel_coverage.extra_note.title" /></h2>
              <div
                className={ styles.fireModeContentBoxTextWrapper }
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({id: 'mapbiomas.warning.sentinel_coverage.extra_note.content'})
                }}
              />
            </div>
          }

          { _.includes(activeModuleContent, 'mining') && <AnnualStatisticsContent { ...defaultContentParams } /> }
          { _.includes([
            'pasture_age_main',
          ], classTreeKey) &&
            <CoverageMainStatisticsContentCustom showOnlyFirstLevel { ...defaultContentParams } />
          }
          { _.includes([
            'pasture_age_detection_year',
            'pasture_age_last_year_mapped',
            'pasture_vigor_main',
            'pasture_productivity_main',
            'agriculture_irrigation_cultivation_frequency_average',
          ], classTreeKey) &&
            <GEESimpleStatisticsContent { ...defaultContentParams } />
          }
          { _.includes(activeModuleContent, 'agriculture') && classTreeKey !== 'agriculture_irrigation_cultivation_frequency_average' && <CoverageMainStatisticsContentCustom { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'temporal_analysis') && <NumberOfClassesStatisticsContent { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'deforestation') && classTreeKey === 'deforestation_annual_by_class' && <AnnualStatisticsContent { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'deforestation') && classTreeKey === 'deforestation_accumulated_by_class' && <FireAccumulatedStatisticsContent localeKey="deforestation" { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'deforestation') && classTreeKey === 'deforestation_frequency_main' && <FrequencyStatisticsContent deforestationMode { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'regeneration') && classTreeKey === 'regeneration_annual_by_class' && <AnnualStatisticsContent { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'regeneration') && classTreeKey === 'regeneration_accumulated_by_class' && <AccumulatedStatisticsContent { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'regeneration') && classTreeKey === 'regeneration_age_main' && <NumberOfClassesStatisticsContent orderByLabelName localeKey="regeneration_number_of_classes_content" { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'fire') && classTreeKey === 'fire_monthly' && <FireMonthlyStatisticsContent { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'fire') && classTreeKey === 'fire_frequency' && <FrequencyStatisticsContent fireMode { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'fire') && classTreeKey === 'fire_accumulated_by_total_burned' && <FireAccumulatedStatisticsContent { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'fire') && classTreeKey === 'fire_accumulated_by_class' && <AccumulatedDistributedStatisticsContent { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'fire') && classTreeKey === 'fire_accumulated_by_natural_anthropic' && <AccumulatedDistributedStatisticsContent { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'fire') && classTreeKey === 'fire_annual_by_total_burned' && <FireAnnualTotalBurnedStatisticsContent { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'fire') && classTreeKey === 'fire_annual_by_class' && <CoverageMainStatisticsContent chartType="bar" { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'fire') && classTreeKey === 'fire_annual_by_natural_anthropic' && <CoverageMainStatisticsContent chartType="bar" { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'fire') && classTreeKey === 'fire_last' && <FrequencyByYearStatisticsContent { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'fire') && classTreeKey === 'fire_size' && <CoverageMainStatisticsContent chartType="bar" { ...defaultContentParams } /> }
          { activeModuleContent === 'coverage:coverage_main' && <CoverageMainStatisticsComponent { ...defaultContentParams } /> }
          { activeModuleContent === 'sentinel:sentinel_coverage' && <CoverageMainStatisticsContent { ...defaultContentParams } /> }
          { activeModuleContent === 'soil:organic_carbon_stock' && <CoverageMainStatisticsContentCustom { ...defaultContentParams } /> }
          { activeModuleContent === 'soil:soil_texture' && <GEESimpleStatisticsContent { ...defaultContentParams } /> }
          { activeModuleContent === 'infrastructure:infrastructure_main' && <CoverageMainStatisticsContentCustom { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'degradation') && !isOnDegradationCrossingMode && <DegradationFragmentationEdgeArea { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'degradation') && isOnDegradationCrossingMode && <DegradationMultipleContent { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'urban') && customViewMode !== 'categoryCrossing' &&<CoverageMainStatisticsContent { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'urban') && customViewMode === 'categoryCrossing' && <UrbanCrossingStatisticsContent { ...defaultContentParams } /> }
        </div>
      </Collapse>
    </div>
  );
}
