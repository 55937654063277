import React, { Fragment } from 'react';
import _ from 'lodash';
import numbro from 'numbro';
import { useQuery } from '@apollo/react-hooks';

import LinearProgress from '@material-ui/core/LinearProgress';

import useGenericDashboardStatistics from '../../../../hooks/useGenericDashboardStatistics';

import styles from './TerritoryClassInfo.module.scss';

import {
  GET_CLASS_TREE_DATA,
} from './query';

const formatNumber = (value, mantissa = 0) => {
  if (value === null || value === undefined) {
    return '-';
  } else if (value === 0) {
    return 0;
  } else {
    return numbro(value).format({
      thousandSeparated: true,
      mantissa
    });
  }
};

function getActiveYearIndex(years, activeYear) {
  if (years.length === 1) return 0;
  return _.findIndex(years, (year) => year === activeYear);
}

function getTotalArea(data, activeYearIndex) {
  const parentClasses = data.filter((d) => d.parentId === null);
  return _.sumBy(parentClasses, (d) => d.data[activeYearIndex]);
}

function getAreaByClasses(areasByPixelValue, pixelValues, formatted = true) {
  const area = _.sumBy(pixelValues, (pixelValue) => areasByPixelValue[pixelValue] || 0);
  return formatted ? formatNumber(area) : area;
}

function getClassesPercentage(areasByPixelValue, pixelValues, totalArea) {
  if (!totalArea) return '0%';
  const totalAreaByPixelValue = getAreaByClasses(areasByPixelValue, pixelValues, false);
  return `${formatNumber((totalAreaByPixelValue / totalArea) * 100, 1)}%`;
}

// function concatText(textArray) {
//   return textArray.reduce((acc, item, index) => {
//     if (index === 0) {
//       return item;
//     } else if (index === textArray.length - 1) {
//       return `${acc} e ${item}`;
//     }
//     return `${acc}, ${item}`;    
//   }, '');
// }

function getAreasByPixelValue(data, activeYearIndex) {
  const areasByPixelValue = {};
  data.forEach((d) => {
    areasByPixelValue[d.pixelValue] = d.data[activeYearIndex];
  });
  return areasByPixelValue;
}

function parseModuleText(classTreeKey, data, years, activeYear) {
  const activeYearIndex = getActiveYearIndex(years, activeYear);
  const totalArea = getTotalArea(data, activeYearIndex);
  const areasByPixelValue = getAreasByPixelValue(data, activeYearIndex);
  const firstYear = _.first(years);
  const lastYear = _.last(years);
  const average = formatNumber(_.reduce(years, (acc, year, index) => acc + getTotalArea(data, index), 0) / years.length);

  switch(classTreeKey) {
    case 'default': {
      return (
        <p className={ styles.sectionTitleBoxInfo }>{
          `Em ${activeYear} o município possui ${getClassesPercentage(areasByPixelValue, [1, 10], totalArea)} de vegetação natural, sendo ${getClassesPercentage(areasByPixelValue, [1], totalArea)} de Formações Florestais e ${getClassesPercentage(areasByPixelValue, [10], totalArea)} de Áreas Naturais não Florestais. 
          O uso agropecuário representa ${getClassesPercentage(areasByPixelValue, [14], totalArea)} da área do município, sendo ${getClassesPercentage(areasByPixelValue, [15], totalArea)} de pastagem, ${getClassesPercentage(areasByPixelValue, [18], totalArea)} de agricultura, ${getClassesPercentage(areasByPixelValue, [9], totalArea)} de silvicultura e ${getClassesPercentage(areasByPixelValue, [21], totalArea)} de mosaico de usos.`
        }</p>
      );
    }
    case 'natural_or_anthropic': {
      return (
        <p className={ styles.sectionTitleBoxInfo }>{
          `De ${firstYear} a ${lastYear} as formações naturais representavam de ${getClassesPercentage(getAreasByPixelValue(data, 0), [97], totalArea)} para ${getClassesPercentage(getAreasByPixelValue(data, years.length - 1), [97], totalArea)} do município.` 
        }</p>
      );
    }
    case 'deforestation_annual_by_class': {
      return (
        <p className={ styles.sectionTitleBoxInfo }>{
          `O município possui supressão de vegetação nativa média de ${average} ha nos últimos ${years.length} anos (de ${firstYear} a ${lastYear}).`
        }</p>
      );
    }
    case 'deforestation_accumulated_by_class': {
      if (!years || !years[0]) return;
      const yearRange = years[0].split('_');
      return (
        <p className={ styles.sectionTitleBoxInfo }>{
          `De ${yearRange[0]} até ${yearRange[1]} existe um total de ${getAreaByClasses(areasByPixelValue, [400])} ha de supressão vegetação primária e ${getAreaByClasses(areasByPixelValue, [600])} ha de supressão vegetação secundária.`
        }</p>
      );
    }
    case 'regeneration_annual_by_class': {
      return (
        <>
          <p className={ styles.sectionTitleBoxInfo }>{
            `A área de vegetação secundária acumulada de ${firstYear} a ${lastYear} é de ${getAreaByClasses(areasByPixelValue, [500, 300])} ha.`
          }</p>
          {/* <p className={ styles.sectionTitleBoxInfo }>{
            `Desse total, XX% (xx.xxx ha) tem mais de 6 anos e XX% (xx.xxx ha) tem mais de 10 anos.`
          }</p> */}
        </>
      );
    }
    case 'pasture_age_main': {
      return (
        <p className={ styles.sectionTitleBoxInfo }>{
          `O município apresenta ${formatNumber(totalArea)} ha de pastagem em ${lastYear}. Sendo que ${getClassesPercentage(areasByPixelValue, [1], totalArea)} (${getAreaByClasses(areasByPixelValue, [1])} ha) possuem baixo vigor.`
        }</p>
      );
    }
    case 'mining_main': {
      return (
        <p className={ styles.sectionTitleBoxInfo }>{
          `O município apresenta ${formatNumber(totalArea)} ha de área de mineração em ${lastYear}. Sendo que ${getClassesPercentage(areasByPixelValue, [200], totalArea)} (${getAreaByClasses(areasByPixelValue, [200])} ha) de garimpo e ${getClassesPercentage(areasByPixelValue, [100], totalArea)} (${getAreaByClasses(areasByPixelValue, [100])} ha) de mineração industrial.`
        }</p>
      );
    }
    case 'agriculture_irrigation_main': {
      return (
        <p className={ styles.sectionTitleBoxInfo }>{
          `O território possui ${getAreaByClasses(areasByPixelValue, [100])} ha de Pivô central de irrigação, ${getAreaByClasses(areasByPixelValue, [200])} ha com Outros sistemas de irrigação e ${getAreaByClasses(areasByPixelValue, [300])} ha de irrigação por Inundação em ${lastYear}.`
        }</p>
      );
    }
    default: {
      return null;
    }
  }
}

export default function TerritoryClassInfo({
  activeYear,
  classTreeKey,
  territoryId,
  statisticsPath,
  additionalParams = {},
}) {
  const statisticsData = useGenericDashboardStatistics({
    classTreeKey,
    toYear: activeYear,
    territoryId,
    statisticsPath,
    ...additionalParams,
  }, null);
  const { loading, data, pixelValues } = statisticsData;

  const locale = localStorage.getItem('locale') || 'pt-BR';

  const { data: classTreeData } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey
    },
  });

  const years = _.orderBy(_.map(data, 'year'));
  const classesLevelsList = _.get(classTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi');

  const parsedData = _.map(pixelValues, (pixelValue) => {
    const nodeData = _.find(classesLevelsList, { pixelValue });
    const stringList = _.get(nodeData, 'i18nStrings');
    const selectedString = _.find(stringList, { language: locale });
    const className = `${ _.join(_.get(nodeData, 'positionInTree'), '.') }. ${ _.get(selectedString, 'stringValue') }`;

    return {
      ...(nodeData || {}),
      name: className,
      data: _.map(years, (year) => {
        const yearData = _.find(data, { year });
        const pixelArea = _.find(_.get(yearData, 'areas'), { pixelId: pixelValue });
        return _.get(pixelArea, 'value', 0);
      })
    };
  });

  const renderInfo = () => {
    if (!parsedData || _.isEmpty(years)) {
      return null;
    }

    return parseModuleText(classTreeKey, parsedData, years, activeYear);
  };

  return (
    <Fragment>
      { loading &&
        <div className={ styles.progressWrapper }>
          <LinearProgress />
        </div>
      }
      { !loading && renderInfo() }
    </Fragment>
  );
}
