import { gql } from 'graphql.macro';

export const GET_BASE_DATA = gql`
  query {
    app @client {
      baseParams {
        territoryType
        territorySubtype
        territoryValueType
        territory
        territories {
          id
          label
          territoryTypeId
          territoryTypeLabel
          territoryBBox
        }
      }
    }
  }
`;

export const GET_TERRITORY = gql`
  query GetTerritory($ids: [Int!]) {
    territoriesByIds(territoriesIds: $ids) {
      id
      i18nStrings {
        language
        stringValue
      }
      territoryGeometry {
        boundingBox
      }
    }
  }
`;
