import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import TerritoryAutocomplete from '../MapBiomasLandingPage/layout/Header/components/BaseForm/components/TerritoryAutocomplete';
import ModuleIconComponent from '../MapBiomasLandingPage/layout/Header/ModuleIconComponent';

import PageFooter from '../MapBiomasLogin/components/PageFooter';

import TerritoryClassInfo from './components/TerritoryClassInfo';
import TerritoryPieClassChart from './components/TerritoryPieClassChart';
import TerritoryHistoricalClassChart from './components/TerritoryHistoricalClassChart';
import TerritoryCustomMap from './components/TerritoryCustomMap';
import TerritorySummaryClassChart from './components/TerritorySummaryClassChart';
import TerritoryClassPercentageInfo from './components/TerritoryClassPercentageInfo';
import TerritoryBarComparison from './components/TerritoryBarComparison';

import logo from './logo.svg';

import styles from './TerritoryDashboard.module.scss';

import {
  GET_BASE_DATA,
  GET_TERRITORY,
} from './query';

const REGENERATION_PIXEL_VALUES = [300, 500, 522];

function Triangle({
  title,
  subtitle,
  color
}) {
  return (
    <div className={ styles.sectionTitleBox }>
      <div className={ styles.triangleArrow } style={{ borderLeftColor: color }} />
      <div className={ styles.triangleArrowReverse } style={{ borderRightColor: color }} />
      <div className={ styles.sectionTitleBoxTitleWrapper }>
        <h1 className={ styles.sectionTitleBoxTitle }>{ title }</h1>
        { subtitle &&
          <h2 className={ styles.sectionTitleBoxSubtitle }>{ subtitle }</h2>
        }
      </div>
    </div>
  );
}

function SquareBlock({
  text,
  color,
}) {
  return (
    <div className={ styles.squareBlock }>
      <span className={ styles.squareBlockHighlight }>{ text }</span>
      <span className={ styles.squareBlockBackground } style={{ backgroundColor: color }} />
    </div>
  );
}

function ClassInfoBox({
  children,
  color,
}) {
  return (
    <div className={ styles.classInfoBox }>
      <span className={ styles.classInfoBoxBlock1 } style={{ backgroundColor: color }} />
      <span className={ styles.classInfoBoxBlock2 } style={{ backgroundColor: color }} />
      { children }
    </div>
  );
}

const MODULES = [
  { key: 'coverage', label: 'Cobertura' },
  // { key: 'coverage_quality', label: '' },
  // { key: 'temporal_analysis', label: '' },
  { key: 'deforestation', label: 'Desmatamento' },
  { key: 'regeneration', label: 'Regeneração' },
  { key: 'pasture', label: 'Pastagem' },
  { key: 'mining', label: 'Mineração' },
  // { key: 'infrastructure', label: '' },
  { key: 'agriculture', label: 'Agricultura' },
  // { key: 'fire', label: 'Fogo' },
  // { key: 'scenarios', label: '' },
  // { key: 'water', label: 'Água' },
];

const DEFAULT_FIRST_YEAR = 1985;
const DEFAULT_YEAR = 2023;

function formatTerritory(territory) {
  return {
    id: territory.id,
    label: territory.label,
    boundingBox: territory.territoryBBox
  };
}

function formatTerritoryFromURL(territory) {
  const locale = localStorage.getItem('locale') || 'pt-BR';
  const stringList = _.get(territory, 'i18nStrings');
  const selectedString = _.find(stringList, { language: locale });
  const boundingBox = _.get(territory, 'territoryGeometry.boundingBox');

  return {
    id: territory.id,
    label: _.get(selectedString, 'stringValue'),
    boundingBox: [
      [_.get(boundingBox, 'yMin'), _.get(boundingBox, 'xMin')],
      [_.get(boundingBox, 'yMax'), _.get(boundingBox, 'xMax')],
    ]
  };
}

export default function TerritoryDashboard(props) {
  const territoryIdFromURL = _.get(props, 'match.params.id');

  const { data: baseData } = useQuery(GET_BASE_DATA);
  const clientTerritory = _.get(baseData, 'app.baseParams.territories[0]');

  const [territory, setTerritory] = useState(clientTerritory ? formatTerritory(clientTerritory) : null);

  const { data: territoryData, loading: loadingTerritoryData } = useQuery(GET_TERRITORY, {
    variables: {
      ids: [Number(territoryIdFromURL)]
    },
    skip: clientTerritory
  });
  const territoryFromURL = _.get(territoryData, 'territoriesByIds[0]');

  useEffect(() => {
    if (clientTerritory) {
      setTerritory(formatTerritory(clientTerritory));
    }
  }, [clientTerritory]);

  useEffect(() => {
    if (!clientTerritory && territoryFromURL) {
      setTerritory(formatTerritoryFromURL(territoryFromURL));
    }
  }, [clientTerritory, territoryFromURL]);

  const bbox = _.get(territory, 'boundingBox');
  const territoryId = _.get(territory, 'id');
  const parsedBBox = [
    [_.get(bbox, '[0][0]'), _.get(bbox, '[0][1]')],
    [_.get(bbox, '[1][0]'), _.get(bbox, '[1][1]')],
  ];

  const handleTerritoryChange = (data) => {
    setTerritory(data);

    if (data) {
      props.history.push('/territorio/' + data.id);
    }
  };

  const renderSectionTitle = (title, subtitle, color) => {
    return (
      <Triangle
        title={ title }
        subtitle={ subtitle }
        color={ color }
      />
    );
  };

  if (!territory && loadingTerritoryData) {
    return (
      <div>
        <p>Carregando...</p>
      </div>
    );
  }

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.content }>
        <a href="https://mapbiomas.org/">
          <img src={ logo } className={ styles.logo } alt="Logo MapBiomas" />
        </a>
        <Paper className={ styles.formBox }>
          <TerritoryAutocomplete
            activeTerritories={ [territory] }
            categoryId={ 4 }
            label={ null }
            placeholder="Selecione um município..."
            onChange={ handleTerritoryChange }
          />
        </Paper>
        <Paper className={ styles.contentBox }>
          <div className={ styles.contentWrapper }>
            <div className={ styles.contentTitleWrapper }>
              <h1 className={ styles.contentTitle }>Conheça os dados do<br />município de<br /><b>{ _.get(territory, 'label') }</b></h1>
              <p>Nesse relatório você terá dados sobre cobertura, desmatamento, regeneração, pastagem, irrigação, mineração, água e fogo</p>
            </div>
            <div className={ styles.modulesBlock }>
              <Grid container spacing={ 2 } alignItems="center">
                { _.map(MODULES, (module) => {
                  return (
                    <Grid item xs={ 3 } key={ module.key }>
                      <li className={ styles.moduleBlock }>
                        <span className={ styles.moduleBlockIcon }>
                          <ModuleIconComponent moduleKey={ module.key } />
                        </span>
                        <span className={ styles.moduleBlockTitle }>{ module.label }</span>
                      </li>
                    </Grid>
                  );
                }) }
              </Grid>
            </div>
            <Grid container spacing={ 2 } alignItems="center" className={ styles.sectionTitleGrid }>
              <Grid item xs={ 6 }>
                { renderSectionTitle('Cobertura e Uso da Terra', undefined, '#16a085') }
              </Grid>
              <Grid item xs={ 6 }>
                <ClassInfoBox color="#16a085">
                  <TerritoryClassInfo
                    activeYear={ DEFAULT_YEAR }
                    classTreeKey="default"
                    territoryId={ territoryId }
                    statisticsPath="coverage_main"
                    additionalParams={{ legend: 'default' }}
                  />
                </ClassInfoBox>
              </Grid>
            </Grid>
            <section className={ styles.moduleContentWrapper }>
              <Grid container spacing={ 2 } alignItems="center">
                <Grid item xs={ 6 }>
                  <div className={ styles.squareBlockWrapper }>
                    <SquareBlock
                      text={ DEFAULT_YEAR }
                      color="#16a085"
                    />
                    <TerritoryCustomMap
                      className={ styles.coverageMap }
                      showBaseLayer={ false }
                      size={ 400 }
                      year={ DEFAULT_YEAR }
                      territoryId={ territoryId }
                      classTreeKey="default"
                      territoryBoundingBox={ parsedBBox }
                      mapPath="coverage_main"
                      additionalParams={{ legend: 'default' }}
                    />
                  </div>
                </Grid>
                <Grid item xs={ 6 }>
                  <TerritoryClassPercentageInfo
                    className={ styles.coveragePercentageInfoBox1 }
                    activeYear={ DEFAULT_YEAR }
                    classTreeKey="default"
                    territoryId={ territoryId }
                    statisticsPath="coverage_main"
                    additionalParams={{ legend: 'default' }}
                    pixelValue={ 1 }
                    color="#78A759"
                    additionalText="do território é coberto por floresta"
                  />
                  <TerritoryClassPercentageInfo
                    className={ styles.coveragePercentageInfoBox2 }
                    activeYear={ DEFAULT_YEAR }
                    classTreeKey="default"
                    territoryId={ territoryId }
                    statisticsPath="coverage_main"
                    additionalParams={{ legend: 'default' }}
                    pixelValue={ 14 }
                    color="#EBC350"
                    additionalText="do território é coberto por agropecuária"
                  />
                  <TerritoryPieClassChart
                    activeYear={ DEFAULT_YEAR }
                    classTreeKey="default"
                    territoryId={ territoryId }
                    statisticsPath="coverage_main"
                    additionalParams={{ legend: 'default' }}
                  />
                </Grid>
                <Grid item xs={ 12 } className={ styles.historicalChartGridItem }>
                  <TerritoryHistoricalClassChart
                    activeYear={ DEFAULT_YEAR }
                    classTreeKey="default"
                    territoryId={ territoryId }
                    statisticsPath="coverage_main"
                    additionalParams={{ legend: 'default' }}
                    chartType="line"
                    legendHeight="54px"
                  />
                </Grid>
              </Grid>
            </section>
            <Grid container spacing={ 2 } alignItems="center" className={ styles.sectionTitleGrid }>
              <Grid item xs={ 6 }>
                { renderSectionTitle('Cobertura e Uso da Terra', 'Natural e Antrópico', '#16a085') }
              </Grid>
              <Grid item xs={ 6 }>
                <ClassInfoBox color="#16a085">
                  <TerritoryClassInfo
                    activeYear={ DEFAULT_YEAR }
                    classTreeKey="natural_or_anthropic"
                    territoryId={ territoryId }
                    statisticsPath="coverage_main"
                    additionalParams={{ legend: 'natural_or_anthropic' }}
                  />
                </ClassInfoBox>
              </Grid>
            </Grid>
            <Grid container spacing={ 2 } alignItems="center">
              <Grid item xs={ 6 }>
                <div className={ styles.squareBlockWrapper }>
                  <SquareBlock
                    text={ DEFAULT_FIRST_YEAR }
                    color="#16a085"
                  />
                  <TerritoryCustomMap
                    year={ DEFAULT_FIRST_YEAR }
                    territoryId={ territoryId }
                    classTreeKey="natural_or_anthropic"
                    territoryBoundingBox={ parsedBBox }
                    mapPath="coverage_main"
                    additionalParams={{ legend: 'natural_or_anthropic' }}
                  />
                </div>
              </Grid>
              <Grid item xs={ 6 }>
                <div className={ styles.squareBlockWrapper }>
                  <SquareBlock
                    text={ DEFAULT_YEAR }
                    color="#16a085"
                  />
                  <TerritoryCustomMap
                    year={ DEFAULT_YEAR }
                    territoryId={ territoryId }
                    classTreeKey="natural_or_anthropic"
                    territoryBoundingBox={ parsedBBox }
                    mapPath="coverage_main"
                    additionalParams={{ legend: 'natural_or_anthropic' }}
                  />
                </div>
              </Grid>
            </Grid>
            <h3 className={ styles.secondaryTitle }>Áreas naturais e antrópicas em { DEFAULT_FIRST_YEAR } e { DEFAULT_YEAR }</h3>
            <TerritoryBarComparison
              activeYear={ DEFAULT_YEAR }
              classTreeKey="natural_or_anthropic"
              territoryId={ territoryId }
              statisticsPath="coverage_main"
              additionalParams={{ legend: 'natural_or_anthropic' }}
              filteredPixelValues={ [97, 98] }
            />
            <Grid container spacing={ 2 } alignItems="center" className={ styles.sectionTitleGrid }>
              <Grid item xs={ 6 }>
                { renderSectionTitle('Desmatamento', undefined, '#e74c3c') }
              </Grid>
              <Grid item xs={ 6 }>
                <ClassInfoBox color="#e74c3c">
                  <TerritoryClassInfo
                    activeYear={ DEFAULT_YEAR }
                    classTreeKey="deforestation_annual_by_class"
                    territoryId={ territoryId }
                    statisticsPath="deforestation_annual"
                    additionalParams={{ fromYear: 1986 }}
                  />
                  <TerritoryClassInfo
                    activeYear={ DEFAULT_YEAR }
                    classTreeKey="deforestation_accumulated_by_class"
                    territoryId={ territoryId }
                    statisticsPath="deforestation_accumulated"
                    additionalParams={{ fromYear: 2008 }}
                  />
                </ClassInfoBox>
              </Grid>
            </Grid>
            <section className={ styles.moduleContentWrapper }>
              <Grid container spacing={ 2 } alignItems="center">
                <Grid item xs={ 6 }>
                  <div className={ styles.squareBlockWrapper }>
                    <SquareBlock
                      text={ DEFAULT_YEAR }
                      color="#e74c3c"
                    />
                    <TerritoryCustomMap
                      year={ DEFAULT_YEAR }
                      territoryId={ territoryId }
                      classTreeKey="deforestation_annual_by_class"
                      territoryBoundingBox={ parsedBBox }
                      mapPath="deforestation_annual"
                      color="black"
                    />
                  </div>
                </Grid>
                <Grid item xs={ 6 }>
                  <TerritorySummaryClassChart
                    activeYear={ DEFAULT_YEAR }
                    color="#e74c3c"
                    classTreeKey="deforestation_annual_by_class"
                    territoryId={ territoryId }
                    statisticsPath="deforestation_annual"
                    additionalParams={{ fromYear: 1987 }}
                  />
                </Grid>
                <Grid item xs={ 12 } className={ styles.historicalChartGridItem }>
                  <TerritoryHistoricalClassChart
                    activeYear={ DEFAULT_YEAR }
                    classTreeKey="deforestation_annual_by_class"
                    territoryId={ territoryId }
                    statisticsPath="deforestation_annual"
                    additionalParams={{ fromYear: 1987 }}
                  />
                </Grid>
              </Grid>
            </section>
            <Grid container spacing={ 2 } alignItems="center" className={ styles.sectionTitleGrid }>
              <Grid item xs={ 6 }>
                { renderSectionTitle('Vegetação Secundária', undefined, '#27ae60') }
              </Grid>
              <Grid item xs={ 6 }>
                <ClassInfoBox color="#27ae60">
                  <TerritoryClassInfo
                    activeYear={ DEFAULT_YEAR }
                    classTreeKey="regeneration_annual_by_class"
                    territoryId={ territoryId }
                    statisticsPath="regeneration_annual"
                    additionalParams={{ fromYear: 1986, pixelValues: REGENERATION_PIXEL_VALUES }}
                  />
                </ClassInfoBox>
              </Grid>
            </Grid>
            <section className={ styles.moduleContentWrapper }>
              <Grid container spacing={ 2 } alignItems="center">
                <Grid item xs={ 6 }>
                  <div className={ styles.squareBlockWrapper }>
                    <SquareBlock
                      text={ DEFAULT_YEAR }
                      color="#27ae60"
                    />
                    <TerritoryCustomMap
                      year={ DEFAULT_YEAR }
                      territoryId={ territoryId }
                      classTreeKey="regeneration_annual_by_class"
                      territoryBoundingBox={ parsedBBox }
                      mapPath="regeneration_annual"
                      additionalParams={{ pixelValues: REGENERATION_PIXEL_VALUES }}
                      color="black"
                    />
                  </div>
                </Grid>
                <Grid item xs={ 6 }>
                  <TerritorySummaryClassChart
                    activeYear={ DEFAULT_YEAR }
                    color="#27ae60"
                    classTreeKey="regeneration_annual_by_class"
                    territoryId={ territoryId }
                    statisticsPath="regeneration_annual"
                    additionalParams={{ fromYear: 1986, pixelValues: REGENERATION_PIXEL_VALUES }}
                  />
                </Grid>
                <Grid item xs={ 12 } className={ styles.historicalChartGridItem }>
                  <TerritoryHistoricalClassChart
                    activeYear={ DEFAULT_YEAR }
                    classTreeKey="regeneration_annual_by_class"
                    territoryId={ territoryId }
                    statisticsPath="regeneration_annual"
                    additionalParams={{ fromYear: 1986, pixelValues: REGENERATION_PIXEL_VALUES }}
                  />
                </Grid>
              </Grid>
            </section>
            <Grid container spacing={ 2 } alignItems="center" className={ styles.sectionTitleGrid }>
              <Grid item xs={ 6 }>
                { renderSectionTitle('Pastagem', undefined, '#e6680f') }
              </Grid>
              <Grid item xs={ 6 }>
                <ClassInfoBox color="#e6680f">
                  <TerritoryClassInfo
                    activeYear={ DEFAULT_YEAR }
                    classTreeKey="pasture_age_main"
                    territoryId={ territoryId }
                    statisticsPath="pasture_age_main"
                  />
                </ClassInfoBox>
              </Grid>
            </Grid>
            <section className={ styles.moduleContentWrapper }>
              <Grid container spacing={ 2 } alignItems="center">
                <Grid item xs={ 6 }>
                  <div className={ styles.squareBlockWrapper }>
                    <SquareBlock
                      text={ DEFAULT_YEAR }
                      color="#e6680f"
                    />
                    <TerritoryCustomMap
                      year={ DEFAULT_YEAR }
                      territoryId={ territoryId }
                      classTreeKey="pasture_age_main"
                      territoryBoundingBox={ parsedBBox }
                      mapPath="pasture_age_main"
                    />
                  </div>
                </Grid>
                <Grid item xs={ 6 }>
                  <TerritorySummaryClassChart
                    activeYear={ DEFAULT_YEAR }
                    color="#e6680f"
                    classTreeKey="pasture_age_main"
                    territoryId={ territoryId }
                    statisticsPath="pasture_age_main"
                  />
                </Grid>
                <Grid item xs={ 12 } className={ styles.historicalChartGridItem }>
                  <TerritoryHistoricalClassChart
                    activeYear={ DEFAULT_YEAR }
                    classTreeKey="pasture_age_main"
                    territoryId={ territoryId }
                    statisticsPath="pasture_age_main"
                    chartType="line"
                    legendHeight="54px"
                  />
                </Grid>
              </Grid>
            </section>
            <Grid container spacing={ 2 } alignItems="center" className={ styles.sectionTitleGrid }>
              <Grid item xs={ 6 }>
                { renderSectionTitle('Mineração', undefined, '#f39c12') }
              </Grid>
              <Grid item xs={ 6 }>
                <ClassInfoBox color="#f39c12">
                  <TerritoryClassInfo
                    activeYear={ DEFAULT_YEAR }
                    classTreeKey="mining_main"
                    territoryId={ territoryId }
                    statisticsPath="mining_main"
                  />
                </ClassInfoBox>
              </Grid>
            </Grid>
            <section className={ styles.moduleContentWrapper }>
              <Grid container spacing={ 2 } alignItems="center">
                <Grid item xs={ 6 }>
                  <div className={ styles.squareBlockWrapper }>
                    <SquareBlock
                      text={ DEFAULT_YEAR }
                      color="#f39c12"
                    />
                    <TerritoryCustomMap
                      year={ DEFAULT_YEAR }
                      territoryId={ territoryId }
                      classTreeKey="mining_main"
                      territoryBoundingBox={ parsedBBox }
                      mapPath="mining_main"
                      color="black"
                    />
                  </div>
                </Grid>
                <Grid item xs={ 6 }>
                  <TerritorySummaryClassChart
                    activeYear={ DEFAULT_YEAR }
                    color="#f39c12"
                    classTreeKey="mining_main"
                    territoryId={ territoryId }
                    statisticsPath="mining_main"
                  />
                </Grid>
                <Grid item xs={ 12 } className={ styles.historicalChartGridItem }>
                  <TerritoryHistoricalClassChart
                    activeYear={ DEFAULT_YEAR }
                    classTreeKey="mining_main"
                    territoryId={ territoryId }
                    statisticsPath="mining_main"
                  />
                </Grid>
              </Grid>
            </section>
            <Grid container spacing={ 2 } alignItems="center" className={ styles.sectionTitleGrid }>
              <Grid item xs={ 6 }>
                { renderSectionTitle('Agricultura', undefined, '#b731ce') }
              </Grid>
              <Grid item xs={ 6 }>
                <ClassInfoBox color="#b731ce">
                  <TerritoryClassInfo
                    activeYear={ DEFAULT_YEAR }
                    classTreeKey="agriculture_irrigation_main"
                    territoryId={ territoryId }
                    statisticsPath="agriculture_irrigation"
                  />
                </ClassInfoBox>
              </Grid>
            </Grid>
            <section className={ styles.moduleContentWrapper }>
              <Grid container spacing={ 2 } alignItems="center">
                <Grid item xs={ 6 }>
                  <div className={ styles.squareBlockWrapper }>
                    <SquareBlock
                      text={ DEFAULT_YEAR }
                      color="#b731ce"
                    />
                    <TerritoryCustomMap
                      year={ DEFAULT_YEAR }
                      territoryId={ territoryId }
                      classTreeKey="agriculture_agricultural_use_main"
                      territoryBoundingBox={ parsedBBox }
                      mapPath="agriculture_agricultural_use_main"
                      color="black"
                    />
                  </div>
                </Grid>
                <Grid item xs={ 6 }>
                  <TerritorySummaryClassChart
                    activeYear={ DEFAULT_YEAR }
                    color="#b731ce"
                    classTreeKey="agriculture_agricultural_use_main"
                    territoryId={ territoryId }
                    statisticsPath="agriculture_agricultural_use_main"
                  />
                </Grid>
                <Grid item xs={ 12 } className={ styles.historicalChartGridItem }>
                  <TerritoryHistoricalClassChart
                    activeYear={ DEFAULT_YEAR }
                    classTreeKey="agriculture_agricultural_use_main"
                    territoryId={ territoryId }
                    statisticsPath="agriculture_agricultural_use_main"
                    chartType="line"
                  />
                </Grid>
              </Grid>
            </section>
          </div>
        </Paper>
      </div>
      <PageFooter />
    </div>
  );
}
