import React, { useEffect, useRef, useState } from "react";
import {
  GeoJSON,
  Map,
  Marker,
  ScaleControl,
  TileLayer,
  WMSTileLayer,
  ZoomControl,
} from "react-leaflet";
import qs from "qs";
import { BoxZoomControl } from "react-leaflet-box-zoom";
import _ from "lodash";
import L from "leaflet";
import classnames from "classnames";
import numbro from "numbro";
import * as turf from "@turf/turf";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import LandscapeIcon from "@material-ui/icons/Landscape";
import NotListedLocationIcon from "@material-ui/icons/NotListedLocation";
import OpacityIcon from "@material-ui/icons/Opacity";
import Tooltip from "@material-ui/core/Tooltip";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";

import useFormatMessage from "../../../../../../hooks/useFormatMessage";
import usePrevious from "../../../../../../hooks/usePrevious";

import styles from "./MapCanvas.module.scss";

import baseLayersData from "../../../../../../data/baseLayers";
import baseMapsData from "../../../../../../data/baseMaps";

import CoordinatesInfo from "./components/CoordinatesInfo";
import MapCustomEvents from "./components/MapCustomEvents";
import DegradationLayer from "./components/DegradationLayer";
import GenericBaseLayer from "./components/GenericBaseLayer";
import GenericLayer from "./components/GenericLayer";
import LeafDrawWrapper from "./components/LeafletDrawWrapper";

import "./leaflet-side-by-side";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const useStyles = makeStyles((theme) => ({
  mapButtonActive: {
    backgroundColor: "#F1F1F1",
    "& svg": {
      fill: `${theme.palette.primary.main} !important`,
    },
  },
}));

const CustomTooltip = withStyles(() => ({
  tooltip: {
    padding: "8px 12px",
    fontSize: 12,
    fontWeight: 400,
    color: "white",
    backgroundColor: "#444444",
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.12)",
  },
  arrow: {
    color: "#444444",
  },
}))(Tooltip);

const HEADER_HEIGHT = 0;
const FOOTER_HEIGHT = 30;
const EXTERNAL_ELEMENTS_HEIGHT = HEADER_HEIGHT + FOOTER_HEIGHT;

const CONFIG_DEFAULT = {
  lat: -14.392118,
  lng: -56.25,
  zoom: 4,
  minZoom: 4,
  maxZoom: 19,
};

const CUSTOM_MAP_URL = "https://brasil.mapserver.mapbiomas.org";

export default function MapCanvas({
  activeBaseMap,
  activeModule,
  activeModuleContent,
  activeYear,
  baseParams,
  carFeature,
  geometry,
  geometryMode,
  isOnUpdatingWarningMode,
  countryTerritoryId,
  defaultClassTreeLevelsList,
  flyTo,
  isMobile,
  mapPointInfo,
  activeLayers,
  layersOpacity,
  height,
  headerIsVisible,
  configOptions = {},
  isOnFreezeMode = false,
  onSearchPointClick = () => {},
  showBeforeAndAfterMosaic,
  showDashboardInfo,
  setCoverageTutorial,
  onMoveEnd,
  onFlyToReset = () => {},
  mapboxMode,
  transitionClassTreeNodeId,
  ruralPropertyCode,
  onCreateGeometry = () => {},
}) {
  const MAP_URL = !_.includes(["soil"], activeModule)
    ? process.env.REACT_APP_WMS_BASE_URL
    : CUSTOM_MAP_URL;
  const API_URL =
    process.env.REACT_APP_GEE_API_URL ||
    "https://staging.api.mapbiomas.org/api/v1/brazil";
  const classes = useStyles();
  let mapRef = useRef();
  let zoomControlRef = useRef();
  const formatMessage = useFormatMessage();
  const [windowHeight, setWindowHeight] = useState(
    window.innerHeight - EXTERNAL_ELEMENTS_HEIGHT
  );
  const [mouseCoordinates, setMouseCoordinates] = useState(null);
  const [isOnBoxZoomMode, setIsOnBoxZoomMode] = useState(false);
  const [isOnPointSearchMode, setIsOnPointSearchMode] = useState(false);
  const [isOnGeographicalRelief, setIsOnGeographicalRelief] = useState(true);
  const [mapZoom, setMapZoom] = useState(4);
  const [leftMapUrl, setLeftMapUrl] = useState(null);
  const [rightMapUrl, setRightMapUrl] = useState(null);
  const [currentTerritories, setCurrentTerritories] = useState([]);
  const config = _.assign({}, CONFIG_DEFAULT, configOptions);
  const activeBaseMapData = _.find(baseMapsData, { id: activeBaseMap });
  const [currentRangeYears, setCurrentRangeYears] = useState([]);
  const yearRange = _.get(baseParams, "yearRange");
  let leftSideMosaicLayer, rightSideMosaicLayer, sideBySideControl;
  const mapboxModePrev = usePrevious(mapboxMode);

  const geometryBBox = geometry ? turf.bbox(geometry) : [];
  const geometryBBoxFlyTo = !_.isEmpty(geometryBBox)
    ? JSON.stringify([
        [geometryBBox[1], geometryBBox[0]],
        [geometryBBox[3], geometryBBox[2]],
      ])
    : null;

  function updateHeight() {
    setWindowHeight(window.innerHeight - EXTERNAL_ELEMENTS_HEIGHT);

    if (mapRef.current) {
      mapRef.current.leafletElement.invalidateSize();
    }
  }
  const debouncedUpdateHeight = _.debounce(updateHeight, 500);

  function handleBoxZoomClick() {
    setIsOnBoxZoomMode(true);
  }

  useEffect(() => {
    setTimeout(() => {
      window.addEventListener("resize", debouncedUpdateHeight);

      const boxZoomButton = document.getElementById("box-zoom-button");

      if (boxZoomButton) {
        boxZoomButton.addEventListener("click", handleBoxZoomClick);
      }
    }, 3000);

    return () => {
      window.removeEventListener("resize", debouncedUpdateHeight);

      const boxZoomButton = document.getElementById("box-zoom-button");

      if (boxZoomButton) {
        boxZoomButton.removeEventListener("click", handleBoxZoomClick);
      }
    };
  }, []);

  useEffect(() => {
    if (mapRef && flyTo && _.isArray(flyTo)) {
      if (_.isArray(_.first(flyTo))) {
        mapRef.current.leafletElement.fitBounds(flyTo);
      } else {
        mapRef.current.leafletElement.flyTo(flyTo, 10);
      }
      onFlyToReset();
    }
  }, [flyTo]);

  useEffect(() => {
    if (
      mapboxModePrev &&
      !mapboxMode &&
      mapRef.current &&
      !_.isEmpty(configOptions)
    ) {
      const { lat, lng, zoom } = configOptions;

      mapRef.current.leafletElement.panTo([lat, lng], zoom);
    }
  }, [mapboxModePrev, mapboxMode]);

  useEffect(() => {
    const url = _.get(activeBaseMapData, "props.url");
    const layerName = _.get(activeBaseMapData, "props.layers");

    if (showBeforeAndAfterMosaic && url && layerName) {
      const [firstYear, lastYear] = _.split(yearRange, "-");

      const territories = _.get(baseParams, "territories");
      if (
        leftMapUrl !== null &&
        rightMapUrl !== null &&
        _.isEqual(
          currentTerritories,
          territories && _.isEqual(currentRangeYears, [firstYear, lastYear])
        )
      ) {
        return;
      } else {
        setCurrentTerritories(territories);
        setCurrentRangeYears([firstYear, lastYear]);
      }

      if (!territories || _.isEmpty(territories)) {
        return null;
      }

      const territoryIds = _.join(_.map(territories, "id"), ",");

      const pathKey = "coverage_main"; //_.last(_.split(activeModuleContent, ":"));
      const parsedLeftParams = {
        territoryIds: territoryIds,
        pixelValues: _.map(defaultClassTreeLevelsList, "id"),
        initiative: "brazil",
        legend: "default",
        year: parseInt(firstYear),
      };
      const parsedRightParams = {
        territoryIds: territoryIds,
        pixelValues: _.map(defaultClassTreeLevelsList, "id"),
        initiative: "brazil",
        legend: "default",
        year: parseInt(lastYear),
      };

      const mapUrlLeft = `${API_URL}/maps/${pathKey}?${qs.stringify(
        parsedLeftParams,
        { indices: false, arrayFormat: "repeat" }
      )}`;

      fetch(mapUrlLeft, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => response.text())
        .then((data) => {
          setLeftMapUrl(data);
          setCurrentTerritories(territories);
        });

      const mapUrlRight = `${API_URL}/maps/${pathKey}?${qs.stringify(
        parsedRightParams,
        { indices: false, arrayFormat: "repeat" }
      )}`;

      fetch(mapUrlRight, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => response.text())
        .then((data) => {
          setRightMapUrl(data);
        });
    }
  }, [activeBaseMapData, showBeforeAndAfterMosaic, layersOpacity, yearRange]);

  useEffect(() => {
    if (showBeforeAndAfterMosaic && leftMapUrl != null && rightMapUrl != null) {
      const [firstYear, lastYear] = _.split(yearRange, "-");
      const layerName = _.get(activeBaseMapData, "props.layers");
      const territories = _.get(baseParams, "territories");

      if (!territories || _.isEmpty(territories)) {
        return null;
      }

      const territoryIds = _.join(_.map(territories, "id"), ",");
      const classTreeLevelsListIds = _.join(
        _.map(defaultClassTreeLevelsList, "id"),
        ","
      );
      leftSideMosaicLayer = L.tileLayer(leftMapUrl, {
        transparent: true,
        zIndex: 100,
        year: firstYear,
        layers: layerName,
        tileType: "wms",
        class_tree_node_ids: classTreeLevelsListIds,
        territory_ids: territoryIds,
      }).addTo(mapRef.current.leafletElement);

      rightSideMosaicLayer = L.tileLayer(rightMapUrl, {
        transparent: true,
        zIndex: 100,
        year: lastYear,
        layers: layerName,
        tileType: "wms",
        class_tree_node_ids: classTreeLevelsListIds,
        territory_ids: territoryIds,
      }).addTo(mapRef.current.leafletElement);

      sideBySideControl = L.control
        .sideBySide(leftSideMosaicLayer, rightSideMosaicLayer)
        .addTo(mapRef.current.leafletElement);

      return () => {
        mapRef.current.leafletElement.removeLayer(
          sideBySideControl.getLeftLayer()
        );
        mapRef.current.leafletElement.removeLayer(
          sideBySideControl.getRightLayer()
        );
        sideBySideControl.remove();
      };
    }
  }, [leftMapUrl, rightMapUrl]);

  useEffect(() => {
    if (geometryBBoxFlyTo) {
      const parsedFlyTo = JSON.parse(geometryBBoxFlyTo);
      mapRef.current.leafletElement.fitBounds(parsedFlyTo, {
        padding: [100, 100],
      });
    }
  }, [geometryBBoxFlyTo]);

  const handleGeometryCreate = (value) => {
    if (value) {
      onCreateGeometry(value);
    }
  };

  const renderBaseMap = () => {
    const classTreeLevelsListIds = _.join(
      _.map(defaultClassTreeLevelsList, "id"),
      ","
    );

    if (showBeforeAndAfterMosaic) {
      return (
        <TileLayer
          key="base-map-tile-layer"
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          zIndex={100}
        />
      );
    }

    if (activeBaseMapData.labelKey === "mosaic") {
      return (
        <>
          <TileLayer
            key="base-map-tile-layer"
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            zIndex={100}
          />
          <GenericBaseLayer
            pathKey="coverage_rgb"
            territoryId={countryTerritoryId}
            activeYear={activeYear}
            baseParams={baseParams}
            zIndex={101}
          />
        </>
      );
    }

    if (!activeBaseMapData.props) {
      if (activeBaseMapData.labelKey === "planet") {
        return [
          <TileLayer
            key="base-map-tile-layer"
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            zIndex={100}
          />,
          <TileLayer
            key="planet-tile-layer"
            attribution='&amp;copy <a href="https://www.planet.com/">Planet</a>'
            url={activeBaseMapData.url}
            zIndex={101}
          />,
        ];
      } else {
        return (
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url={activeBaseMapData.url}
            zIndex={100}
          />
        );
      }
    } else {
      const year = _.isArray(activeYear) ? _.last(activeYear) : activeYear;

      return [
        <TileLayer
          key="base-map-tile-layer"
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          zIndex={100}
        />,
        <WMSTileLayer
          key="base-map-custom-tile-layer"
          {...activeBaseMapData.props}
          class_tree_node_ids={classTreeLevelsListIds}
          territory_ids={countryTerritoryId}
          year={year}
          zIndex={101}
        />,
      ];
    }
  };

  const renderCoverageLayer = () => {
    return (
      <GenericLayer
        activeModuleContent={activeModuleContent}
        activeYear={activeYear}
        baseParams={baseParams}
        opacity={layersOpacity / 100}
        ruralPropertyCode={ruralPropertyCode}
        geometry={geometry}
      />
    );
  };

  const renderTransitionLayer = () => {
    return (
      <GenericLayer
        activeModuleContent={activeModuleContent}
        activeYear={activeYear}
        baseParams={baseParams}
        opacity={layersOpacity / 100}
        ruralPropertyCode={ruralPropertyCode}
        geometry={geometry}
      />
    );
  };

  const renderQualityLayer = () => {
    const territories = _.get(baseParams, "territories");

    if (!territories || _.isEmpty(territories)) {
      return null;
    }

    const territoryIds = _.join(_.map(territories, "id"), ",");

    return (
      <WMSTileLayer
        transparent
        format="image/png"
        layers="quality_of_data"
        url={`${MAP_URL}/wms/quality_of_data.map`}
        territory_ids={territoryIds}
        year={activeYear}
        zIndex={200}
        opacity={layersOpacity / 100}
      />
    );
  };

  const renderPastureQualityQualityLayer = () => {
    const territories = _.get(baseParams, "territories");
    const activePastureQualityQualityClassesListItems = _.get(
      baseParams,
      "activePastureQualityQualityClassesListItems"
    );

    const enabledPixelValues = _.join(
      activePastureQualityQualityClassesListItems,
      ","
    );

    if (!territories || _.isEmpty(territories)) {
      return null;
    }

    const territoryIds = _.join(_.map(territories, "id"), ",");

    return (
      <WMSTileLayer
        transparent
        format="image/png"
        layers="pasture_quality_coverage"
        url={`${MAP_URL}/wms/pasture_quality_coverage.map`}
        territory_ids={territoryIds}
        year={activeYear}
        enabled_pixel_values={enabledPixelValues}
        zIndex={200}
        opacity={layersOpacity / 100}
      />
    );
  };

  const renderInfrastructureCoverageLayer = ({
    opacityDenominator = 100,
    objectsMask = true,
  } = {}) => {
    const territories = _.get(baseParams, "territories");
    const bufferId = _.get(baseParams, "buffer");
    const activeObjectTreeNodeIds = _.join(
      _.get(baseParams, "activeObjectTreeNodeIds")
    );
    const activeClassTreeNodeIds = _.join(
      _.get(baseParams, "activeClassTreeNodeIds")
    );

    if (!territories || _.isEmpty(territories)) {
      return null;
    }

    const territoryIds = _.join(_.map(territories, "id"), ",");

    return (
      <WMSTileLayer
        transparent
        format="image/png"
        layers={objectsMask ? "coverage" : "coverage_without_objects_mask"}
        url={`${MAP_URL}/wms/infrastructure_coverage.map`}
        territory_ids={territoryIds}
        year={activeYear}
        class_tree_node_ids={activeClassTreeNodeIds}
        buffer_id={bufferId}
        object_tree_node_ids={activeObjectTreeNodeIds}
        zIndex={200}
        opacity={layersOpacity / opacityDenominator}
      />
    );
  };

  const renderObjectLayer = () => {
    const activeSubmodule = _.get(baseParams, "activeSubmodule");
    const activeObjectTreeNodeIds = _.join(
      _.get(baseParams, "activeObjectTreeNodeIds")
    );

    if (!activeObjectTreeNodeIds || _.isEmpty(activeObjectTreeNodeIds)) {
      return null;
    }

    return (
      <WMSTileLayer
        transparent
        format="image/png"
        layers="objects_point,objects_line"
        url={`${MAP_URL}/wms/${activeSubmodule}_objects.map`}
        object_tree_node_ids={activeObjectTreeNodeIds}
        zIndex={300}
        opacity={layersOpacity / 100}
      />
    );
  };

  const renderGenericLayer = () => {
    if (
      _.includes(
        [
          "coverage",
          "regeneration",
          "deforestation",
          "temporal_analysis",
          "mining",
          "agriculture",
          "pasture",
          "coverage_quality",
          "urban",
          "soil",
          "infrastructure",
        ],
        activeModule
      )
    ) {
      return (
        <GenericLayer
          activeModuleContent={activeModuleContent}
          activeYear={activeYear}
          baseParams={baseParams}
          opacity={layersOpacity / 100}
          ruralPropertyCode={ruralPropertyCode}
          zoom={mapZoom}
          geometry={geometry}
        />
      );
    }

    const territories = _.get(baseParams, "territories");
    const activeSubmodule = _.get(baseParams, "activeSubmodule");
    const activeClassTreeNodeIds = _.join(
      _.get(baseParams, "activeClassTreeNodeIds")
    );
    // TODO: Do something with activeBaseClassTreeNodeIds param
    const activeBaseClassTreeNodeIds = _.get(
      baseParams,
      "activeBaseClassTreeNodeIds"
    );
    let mapPathname = activeSubmodule;

    if (!territories || _.isEmpty(territories)) {
      return null;
    }

    const territoryIds = _.join(_.map(territories, "id"), ",");

    let yearParams = {};

    if (activeModuleContent === "fire:fire_frequency") {
      yearParams = {
        from_year: _.first(activeYear),
        to_year: _.last(activeYear),
      };
    } else if (
      activeModuleContent ===
        "temporal_analysis:temporal_analysis_stable_areas" ||
      activeModuleContent ===
        "temporal_analysis:temporal_analysis_number_of_changes" ||
      activeModuleContent ===
        "temporal_analysis:temporal_analysis_number_of_classes"
    ) {
      mapPathname = _.get(baseParams, "activeClassTreeOptionValue");
      yearParams = {
        from_year: _.first(activeYear),
        to_year: _.last(activeYear),
        year: _.join(activeYear, ""),
      };
    } else {
      if (_.isArray(activeYear)) {
        yearParams = {
          year: _.last(activeYear),
        };
      } else {
        yearParams = {
          year: activeYear,
        };
      }
    }

    return (
      <WMSTileLayer
        transparent
        format="image/png"
        key={`generic-layer-${JSON.stringify(yearParams)}`}
        layers={activeSubmodule}
        url={`${MAP_URL}/wms/${mapPathname}.map`}
        class_tree_node_ids={activeClassTreeNodeIds}
        territory_ids={territoryIds}
        zIndex={200}
        opacity={layersOpacity / 100}
        {...yearParams}
      />
    );
  };

  const renderDegradationLayer = () => {
    return <DegradationLayer activeYear={activeYear} baseParams={baseParams} />;
  };

  const renderModuleContentLayer = () => {
    if (activeModuleContent === "coverage:coverage_main") {
      return renderCoverageLayer();
    } else if (activeModuleContent === "coverage:coverage_changes") {
      return renderTransitionLayer();
      // } else if (activeModuleContent === 'coverage:coverage_quality' || activeModuleContent === 'coverage_quality:coverage_quality_main') {
      //   return renderQualityLayer();
      // } else if (activeModuleContent === 'quality_of_pasture_data:pasture_quality_main') {
      //   return renderPastureQualityQualityLayer();
    // } else if (
    //   activeModuleContent === "infrastructure:infrastructure_main" &&
    //   _.get(baseParams, "buffer") === 0
    // ) {
    //   return [
    //     renderInfrastructureCoverageLayer({
    //       opacityDenominator: 200,
    //       objectsMask: false,
    //     }),
    //     renderObjectLayer(),
    //   ];
    // } else if (activeModuleContent === "infrastructure:infrastructure_main") {
    //   return renderInfrastructureCoverageLayer();
    } else if (activeModule === "degradation") {
      return renderDegradationLayer();
    } else {
      return renderGenericLayer();
    }
  };

  const renderBaseLayers = () => {
    return _.map(activeLayers, (key) => {
      const layerData = _.find(baseLayersData, { key });

      if (_.get(layerData, 'type') !== 'wmts') {
        return (
          <GenericBaseLayer
            pathKey="layer"
            layerKey={layerData.key}
            zIndex={300 + layerData.index}
          />
        );
      } else {
        return (
          <TileLayer
            transparent
            format='image/png'
            url={`${layerData.props.url}?layer=${layerData.props.layers}&style=&tilematrixset=EPSG%3A3857&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fpng&TileMatrix=EPSG%3A3857%3A{z}&TileCol={x}&TileRow={y}`}
            zIndex={300 + layerData.index}
          />
        );
      }
    });
  };

  const renderCARFeature = () => {
    if (!carFeature) {
      return null;
    }

    return (
      <GeoJSON
        zIndex={400}
        key={`land-registry-${_.get(carFeature, "id")}`}
        data={carFeature}
        style={{
          color: "#ff7800",
          weight: 3,
          opacity: 0.85,
        }}
      />
    );
  };

  const handleMapClick = (event) => {
    if (!isOnBoxZoomMode && !isOnFreezeMode && isOnPointSearchMode) {
      const { lat, lng } = event.latlng;
      const { x, y } = event.layerPoint;
      const point1 = { x, y: y - 1 };
      const point2 = { x, y: y + 1 };

      const parsedPoint1 =
        mapRef.current.leafletElement.layerPointToLatLng(point1);
      const parsedPoint2 =
        mapRef.current.leafletElement.layerPointToLatLng(point2);

      onSearchPointClick({
        __typename: "MapPointInfo",
        parsedBBox: [
          _.get(parsedPoint2, "lng"),
          _.get(parsedPoint2, "lat"),
          _.get(parsedPoint1, "lng"),
          _.get(parsedPoint1, "lat"),
        ],
        latlng: [lat, lng],
      });
    }
  };

  const handleMouseMove = (event) => {
    const { lat, lng } = event.latlng;
    const formatNumber = (value) => {
      const formattedValue = numbro(value).format({
        thousandSeparated: true,
        mantissa: 2,
      });

      return _.replace(formattedValue, ",", ".");
    };

    setMouseCoordinates(`${formatNumber(lat)}, ${formatNumber(lng)}`);
  };

  const handleMoveEnd = (event) => {
    if (onMoveEnd) {
      const center = event.target.getCenter();
      const zoom = event.target.getZoom();
      const combinedValue = `${center.lat.toFixed(6)},${center.lng.toFixed(
        6
      )},${zoom}`;

      onMoveEnd(combinedValue);
    }
  };

  const handleZoomEnd = (event) => {
    const zoom = event.target.getZoom();
    setMapZoom(zoom);
  };

  const stopZoomControl = () => {
    if (zoomControlRef.current) {
      zoomControlRef.current.stop();
    }

    setIsOnBoxZoomMode(false);
  };

  const togglePointSearchMode = (event) => {
    event.stopPropagation();
    setIsOnPointSearchMode(!isOnPointSearchMode);
  };

  const toggleGeographicalReliefhMode = (event) => {
    event.stopPropagation();
    setIsOnGeographicalRelief(!isOnGeographicalRelief);
  };

  return (
    <Map
      ref={mapRef}
      className={classnames(styles.mapWrapper, {
        [styles.mapWrapperWithHeader]: headerIsVisible,
        [styles.mapWrapperOnPointSearchMode]: isOnPointSearchMode,
      })}
      center={[config.lat, config.lng]}
      zoom={config.zoom}
      // minZoom={ config.minZoom }
      // maxZoom={ config.maxZoom }
      style={{ height: height || windowHeight }}
      zoomControl={false}
      onClick={handleMapClick}
      onmousemove={handleMouseMove}
      onmoveend={handleMoveEnd}
      onzoomend={handleZoomEnd}
      {...configOptions}
    >
      <ZoomControl position="bottomleft" />
      <ScaleControl position="bottomleft" imperial={false} />
      <BoxZoomControl
        position="bottomleft"
        ref={zoomControlRef}
        sticky={true}
        className="t-zoombox"
        style={{
          position: "relative",
          bottom: "-81px",
          width: "32px",
          height: "32px",
          margin: "0px",
          boxShadow:
            "0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24)",
          border: "none",
          backgroundColor: "white",
          backgroundImage:
            "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAADJUlEQVRIS+WWQWgTQRSGZ1KItiexIFVDyM6YnFS8CLZisQg9ain24qWtoJeS2h4ys0k97KVhZpsarCiUiqIiFIoGPRUEsRf14KHiLe3ORG3VQ1GsSGhosrJgwpJks0khVHCO+/73vvd23nu7EOzSgbvEBSUwpXRJCNFtTyQUCvknJyc/E0KiUsp40aYoygVd159TSruFEEt2H4zxHcbYiFtBJfDAwIBZLg4EAqGpqakVSukNIcR40Y4QGuScPySEnJdSPrP7IYTec85PNAxGCJ2BEK5tbW1tJpPJ71YATdM82WzWByH0FAqFnK7rX4qBI5FIR0tLy14AQK9hGLM7BmOMFcZYxi3jcjshpE9Kmdox2OfztRcrbQQejUY7V1dXXyOEXnDOe918S3cci8UO5nK5vYlEQro5OdljsdhRr9f7SdO0TbcY/8Q4XQcAdLS2to5qmlaolXE0Gm3P5/N9EMLTAIADpmluQgiXIYRPGWOrbtVa9opx8vv9genp6Y8OzpAQck1KmXQKjhCay+fz44lE4netBCrANboaUkrvCiEuu1WEEFoGAJzlnP900laAg8HgoXg8/rXKuIxKKW+6QW1LJsU573cFq6raa5rmfs75fLl4bGxs3/r6+o9qQTDGM4ZhjDrYehhjr6rZ6upqVVWHDMO4Xy3AwsICrLZuLS3G+AFjbKgmuFbFhJBZKeXVRsGW3kqsJriYdbU7ppSmhBB9tvub45xXJEIpvSSEeGwH1Q2u1tWU0ntCiOGmVowQikAINwAA3xhjixaMUhoWQsw0CkYIPeGcX6zrVdtFiqKc1HX93cTExOF0Or1WLYCiKCNSytsOXd3PGEvVBBNCmMfjOVUUmaa5YZrmsK7rv6xnhBBdShlpYI7fcs67AAAVPxhWjLrGyRJqmubNZrOLQoieeuAY43OMsZeuC6SeYOFweE9bW9stIcSVevQY46DTR6Puiu0gSukx0zQHAQBdUsrOv8viEYRwfnt7eyWTyaSLeif4jsBu1aqqesQwjBXb3B/nnH+w+zUFbAHK4eWLpGlgO1xRlDe6rlsdXjpNBde6kv8P/Ac2H3ou8vCdygAAAABJRU5ErkJggg==)",
          backgroundSize: "80%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          outline: "none",
          cursor: "pointer",
          borderRadius: "6px",
        }}
        activeStyle={{
          backgroundColor: "#F1F1F1",
          backgroundImage:
            "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAADQElEQVRIS+WWTWgTURDH30shtj2JBakfiIJ68QMvgq1m0yrW7CaxoXjy0lbQS0ltD3lvk3rYS8N729RgRaFUFBWhF41tdpNgoTS7YhU8VMSLFfwq6qEoViQ0NFlZMGXJ1yaFUsF33PnP/Gb2zcwuBBt04AZxwSoYY5xkFYkxJjJ10rNrcHDwM0LIz6lyMGeL2ZztoihOYowZVpGSRp+E3X2TENJjVtAqeKbpgJYvlk9w+4eGhuYxxldZRerP2eOMq5NSeg8hdJZT5QmjX5xxvaKUHqkaHGdcNgjhwvLy8lI4HP6uBxAEwZJKpXZCCC3ZbDYtiuKXXGCfz9dYU1NTCwBocySjo2sGJ+zuPYSQD2YZ59sRQh5OlSNrBk8ca2vIVVoN3O/3N52ZmXwWZ1xTlNI2M9/VOw4EAtvS6XRtKBR6b+ZUyh4IBA5ardZPgiAsmcX4J8bpCgCgsa6urlcQhGy5jP1+f0Mmk/FACI8DALZqmrYEIZyDED4ihLwzq1a3F4xTtNmxe3h4+GMJZ4gQusypcrhU8DjjGstkMv2hUOh3uQQKwGW6GmKMb7GKdMGsojjjmgMAtFBKf5bSFoCftLZvDwaDX4uMSy+nytfMoIYlE6GUdpiCeZ5v0zRtC6V0PF/c19e32fNi6kexIAm7e8SRjPaWsLUSQmaK2Srqap7nuxzJ6J1iAVpm38Bi61bXJuzuu4SQrrLgchUjhEY5Vb5ULVjX64mVBeeyLnbHGOMIq0gew/2NUUoLEsEYn2cV6YERVDG4WFdjjG+zitS9rhXHGZcPQrgIAPhGCEnoMIyxl1WkkWrBccb1kFJ6rqJXbRTFbM6joii+HBgY2HF6+vFCsQAxm7OHU+UbJbq6gxASKQtGCBGLxXIsJ9I0bVHTtG5RFH/pzxBCIqfKvirm+DmltBkAUPCDoceoaJx0oSAI1lQqlWAVqbUSeMLuPkUImTZdIJUE83q9m+rr66+zinSxEn3C7t5X6qNRccVGEMb4kKZpnQCAZk6Vm/4ui/sQwvGVlZV559PY25y+FHxNYLNqeZ7f60hG5w1zf5hS+troty5gHZAPz18k6wY2wmM256woinqHr551BZe7kv8P/Ac5JIEu+h5xfgAAAABJRU5ErkJggg==)",
        }}
      />
      {!(isMobile && showDashboardInfo) && (
        <CustomTooltip
          arrow
          title={formatMessage("map_controls.point_info.title")}
          placement="right"
        >
          <button
            className={classnames(
              styles.mapActionButton,
              styles.mapSearchButton,
              {
                [classes.mapButtonActive]: isOnPointSearchMode,
              }
            )}
            onClick={togglePointSearchMode}
            id="info-point"
          >
            <NotListedLocationIcon />
          </button>
        </CustomTooltip>
      )}
      {!isMobile && false && (
        <CustomTooltip
          arrow
          title={formatMessage("mapbiomas.tutorial.title")}
          placement="right"
        >
          <button
            id="tutorial-icon"
            className={classnames(
              styles.mapActionButton,
              styles.tutorialButton
            )}
            onClick={() => setCoverageTutorial(true)}
          >
            <LibraryBooksIcon />
          </button>
        </CustomTooltip>
      )}
      {/* <Tooltip title="Visualização em relevo" placement="right">
        <button
          className={ classnames(styles.mapActionButton, styles.mapGeographicalReliefButton, {
            [classes.mapButtonActive]: isOnGeographicalRelief
          }) }
          onClick={ toggleGeographicalReliefhMode }
        >
          <LandscapeIcon />
        </button>
      </Tooltip> */}
      {renderBaseMap()}
      { !isOnUpdatingWarningMode && renderModuleContentLayer() }
      { !isOnUpdatingWarningMode && renderBaseLayers() }
      {renderCARFeature()}
      {flyTo && _.isNumber(_.first(flyTo)) && <Marker position={flyTo} />}
      {mapPointInfo && isOnPointSearchMode && (
        <Marker position={_.get(mapPointInfo, "latlng")} />
      )}
      <MapCustomEvents
        onMoveStart={stopZoomControl}
        onZoomStart={stopZoomControl}
        onEsc={stopZoomControl}
      />
      <CoordinatesInfo
        headerIsVisible={headerIsVisible}
        mouseCoordinates={mouseCoordinates}
      />
      <LeafDrawWrapper
        geometryMode={geometryMode}
        onCreate={handleGeometryCreate}
      />
    </Map>
  );
}
