import React, { Fragment } from 'react';
import _ from 'lodash';
import { useIntl } from 'react-intl';

import LinearProgress from '@material-ui/core/LinearProgress';

import BaseValuesChart from '../../../MapBiomasLandingPage/views/Map/components/StatisticsBox/components/BaseValuesChart';

import useGenericDashboardStatistics from '../../../../hooks/useGenericDashboardStatistics';

export default function TerritorySummaryClassChart({
  activeYear = 2023,
  color,
  classTreeKey = 'agriculture_agricultural_use_main',
  territoryId = 10001,
  statisticsPath,
  additionalParams = {},
}) {
  const statisticsData = useGenericDashboardStatistics({
    classTreeKey,
    toYear: activeYear,
    territoryId,
    statisticsPath,
    ...additionalParams,
  });
  const { loading, data } = statisticsData;

  const intl = useIntl();

  const areaByPeriod = () => {
    if (!data) {
      return null;
    }

    const total = _.get(_.find(data, { year: activeYear }), 'areaTotal') || 0;
    const maxItem = _.maxBy(data, 'areaTotal');
    const maxValue = _.get(maxItem, 'areaTotal') || 0;
    const maxYear = _.get(maxItem, 'year');
    const averageValue = _.meanBy(data, 'areaTotal') || 0;
    const minItem = _.minBy(data, 'areaTotal');
    const minValue = _.get(minItem, 'areaTotal') || 0;
    const minYear = _.get(minItem, 'year');
    const averagePercentage = (averageValue / maxValue) * 100;
    const minPercentage = (minValue / maxValue) * 100;

    return (
      <BaseValuesChart
        title={ `${intl.formatMessage({id: 'mapbiomas.statistics.area.title'})} - ${ activeYear }` }
        totalTitle="Total"
        total={ total }
        maxValue={ maxValue }
        maxYear={ maxYear }
        averageValue={ averageValue }
        averagePercentage={ averagePercentage }
        minValue={ minValue }
        minYear={ minYear }
        minPercentage={ minPercentage }
        color={ color }
      />
    );
  };

  return (
    <Fragment>
      { loading &&
        <div>
          <LinearProgress />
        </div>
      }
      { !loading && areaByPeriod() }
    </Fragment>
  );
}
