import _ from 'lodash';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { GET_CLASS_TREE_DATA } from '../graphql/queries/general';

const API_URL =
  process.env.REACT_APP_GEE_API_URL ||
  'https://staging.api.mapbiomas.org/api/v1/brazil';

const mapClassTreeKeyToPath = {
  'agriculture_agricultural_use_main': 'agriculture_agricultural_use',
  'regeneration_annual_by_class': 'regeneration_annual'
};

export default function useGenericDashboardStatistics(params, levelToShow = 1) {
  const {
    classTreeKey,
    fromYear = 1985,
    toYear,
    territoryId,
    statisticsPath,
    filteredPixelValues,
    ...additionalParams
  } = params;

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const { data: classTreeData } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey,
    },
  });

  let classTreeNodes = _.get(classTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi');
  if (levelToShow !== null) {
    classTreeNodes = _.filter(classTreeNodes, { level: levelToShow });
  }

  const pixelValues = _.filter(
    _.map(classTreeNodes, 'pixelValue'),
    _.isNumber
  );

  let parsedParams = {
    initiative: 'brazil',
    fromYear,
    toYear,
    pixelValues: filteredPixelValues || pixelValues,
    territoryIds: [territoryId],
    ...(additionalParams || {})
  };

  let urlPath = '/statistics/' + (mapClassTreeKeyToPath[classTreeKey] || statisticsPath || classTreeKey);

  const statisticsUrl = `${ API_URL }${ urlPath }?${ qs.stringify(
    parsedParams,
    { arrayFormat: 'repeat' }
  ) }`;

  useEffect(() => {
    if (_.includes(statisticsUrl, 'pixelValues')) {
      setLoading(true);
      fetch(statisticsUrl, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      })
        .then((response) => response.json())
        .then((data) => {
          setData(data);
          setLoading(false);
        });
    }
  }, [statisticsUrl]);

  return {
    loading,
    data,
    pixelValues: parsedParams.pixelValues
  };
}
