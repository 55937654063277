import React from 'react';
import ReactEcharts from 'echarts-for-react';
import _ from 'lodash';

export default function BarChart({
  categories = [],
  series = [],
}) {
  return (
    <ReactEcharts
      style={ { height: 500 } }
      option={ {
        tooltip: {
          confine: true,
          textStyle: {
            align: "left",
            color: "#444",
            fontSize: 13
          },
          backgroundColor: "#FFFFFF",
          borderColor: "rgba(0, 0, 0, 0.1)",
          borderWidth: 1,
          extraCssText: "box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.05);",
          trigger: "axis",
        },
        grid: {
          top: "8%",
          right: "20px",
          bottom: "8%",
          left: "20px",
          containLabel: true
        },
        xAxis: [
          {
            type: 'value',
            min: -100,
            max: 100,
          }
        ],
        legend: {
          show: false
        },
        yAxis: [
          {
            type: 'category',
            axisTick: {
              show: false
            },
            data: categories
          }
        ],
        series: _.map(series, (serie) => {
          return {
            ...serie,
            stack: 'classId',
            type: 'bar',
            smooth: true,
          };
        }),
        toolbox: {
          feature: {
            saveAsImage: {
              name: 'MapBiomas',
              title: 'Download',
            }
          }
        },
      } }
      notMerge={ true }
    />
  );
}
