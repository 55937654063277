export default [
  {
    "id": 1,
    "color": null,
    "positionInTree": [
      1
    ],
    "level": 1,
    "parentId": null,
    "childrenIds": [
      6,
      7,
      8,
      9,
      10
    ],
    "i18nStrings": [
      {
        "stringValue": "Transportes",
        "language": "pt-BR",
        "__typename": "I18nString"
      },
      {
        "stringValue": "Transportation",
        "language": "en",
        "__typename": "I18nString"
      }
    ],
    "geometryCategory": null,
    "__typename": "GeospatialObjectCategoryTreeLevel"
  },
  {
    "id": 6,
    "color": null,
    "positionInTree": [
      1,
      1
    ],
    "level": 2,
    "parentId": 1,
    "childrenIds": [
      20,
      21,
      22
    ],
    "i18nStrings": [
      {
        "stringValue": "Aeroviário",
        "language": "pt-BR",
        "__typename": "I18nString"
      },
      {
        "stringValue": "Airway",
        "language": "en",
        "__typename": "I18nString"
      }
    ],
    "geometryCategory": null,
    "__typename": "GeospatialObjectCategoryTreeLevel"
  },
  {
    "id": 53,
    "color": "#07BCDC",
    "positionInTree": [
      1,
      1,
      1
    ],
    "level": 3,
    "parentId": 6,
    "childrenIds": [],
    "i18nStrings": [
      {
        "stringValue": "Aeródromos públicos",
        "language": "pt-BR",
        "__typename": "I18nString"
      },
      {
        "stringValue": "Public airfield",
        "language": "en",
        "__typename": "I18nString"
      }
    ],
    "geometryCategory": {
      "i18nStrings": [
        {
          "stringValue": "Aeródromos públicos",
          "language": "pt-BR",
          "__typename": "I18nString"
        },
        {
          "stringValue": "Public airfield",
          "language": "en",
          "__typename": "I18nString"
        }
      ],
      "__typename": "GeometryCategory"
    },
    "__typename": "GeospatialObjectCategoryTreeLevel"
  },
  {
    "id": 61,
    "color": "#9319A5",
    "positionInTree": [
      1,
      1,
      2
    ],
    "level": 3,
    "parentId": 6,
    "childrenIds": [],
    "i18nStrings": [
      {
        "stringValue": "Aeródromos privados",
        "language": "pt-BR",
        "__typename": "I18nString"
      },
      {
        "stringValue": "Private airfield",
        "language": "en",
        "__typename": "I18nString"
      }
    ],
    "geometryCategory": {
      "i18nStrings": [
        {
          "stringValue": "Aeródromos privados",
          "language": "pt-BR",
          "__typename": "I18nString"
        },
        {
          "stringValue": "Private airfield",
          "language": "en",
          "__typename": "I18nString"
        }
      ],
      "__typename": "GeometryCategory"
    },
    "__typename": "GeospatialObjectCategoryTreeLevel"
  },
  {
    "id": 62,
    "color": "#0FA9EF",
    "positionInTree": [
      1,
      1,
      3
    ],
    "level": 3,
    "parentId": 6,
    "childrenIds": [],
    "i18nStrings": [
      {
        "stringValue": "Helipontos",
        "language": "pt-BR",
        "__typename": "I18nString"
      },
      {
        "stringValue": "Helipad",
        "language": "en",
        "__typename": "I18nString"
      }
    ],
    "geometryCategory": {
      "i18nStrings": [
        {
          "stringValue": "Helipontos",
          "language": "pt-BR",
          "__typename": "I18nString"
        },
        {
          "stringValue": "Helipad",
          "language": "en",
          "__typename": "I18nString"
        }
      ],
      "__typename": "GeometryCategory"
    },
    "__typename": "GeospatialObjectCategoryTreeLevel"
  },
  {
    "id": 7,
    "color": null,
    "positionInTree": [
      1,
      2
    ],
    "level": 2,
    "parentId": 1,
    "childrenIds": [
      23,
      24,
      25,
      26,
      27,
      28,
      29,
      30
    ],
    "i18nStrings": [
      {
        "stringValue": "Aquaviário",
        "language": "pt-BR",
        "__typename": "I18nString"
      },
      {
        "stringValue": "Waterway",
        "language": "en",
        "__typename": "I18nString"
      }
    ],
    "geometryCategory": null,
    "__typename": "GeospatialObjectCategoryTreeLevel"
  },
  {
    "id": 15,
    "color": "#E8F41B",
    "positionInTree": [
      1,
      2,
      1
    ],
    "level": 3,
    "parentId": 7,
    "childrenIds": [],
    "i18nStrings": [
      {
        "stringValue": "Eclusa",
        "language": "pt-BR",
        "__typename": "I18nString"
      },
      {
        "stringValue": "Lock",
        "language": "en",
        "__typename": "I18nString"
      }
    ],
    "geometryCategory": {
      "i18nStrings": [
        {
          "stringValue": "Eclusa",
          "language": "pt-BR",
          "__typename": "I18nString"
        },
        {
          "stringValue": "Lock",
          "language": "en",
          "__typename": "I18nString"
        }
      ],
      "__typename": "GeometryCategory"
    },
    "__typename": "GeospatialObjectCategoryTreeLevel"
  },
  {
    "id": 16,
    "color": "#1FFCAB",
    "positionInTree": [
      1,
      2,
      2
    ],
    "level": 3,
    "parentId": 7,
    "childrenIds": [],
    "i18nStrings": [
      {
        "stringValue": "Estação de Transbordo de Cargas",
        "language": "pt-BR",
        "__typename": "I18nString"
      },
      {
        "stringValue": "Transshipment Station",
        "language": "en",
        "__typename": "I18nString"
      }
    ],
    "geometryCategory": {
      "i18nStrings": [
        {
          "stringValue": "Estação de Transbordo de Cargas",
          "language": "pt-BR",
          "__typename": "I18nString"
        },
        {
          "stringValue": "Transshipment Station",
          "language": "en",
          "__typename": "I18nString"
        }
      ],
      "__typename": "GeometryCategory"
    },
    "__typename": "GeospatialObjectCategoryTreeLevel"
  },
  {
    "id": 19,
    "color": "#D962CB",
    "positionInTree": [
      1,
      2,
      3
    ],
    "level": 3,
    "parentId": 7,
    "childrenIds": [],
    "i18nStrings": [
      {
        "stringValue": "Hidrovia",
        "language": "pt-BR",
        "__typename": "I18nString"
      },
      {
        "stringValue": "Waterway",
        "language": "en",
        "__typename": "I18nString"
      }
    ],
    "geometryCategory": {
      "i18nStrings": [
        {
          "stringValue": "Hidrovia",
          "language": "pt-BR",
          "__typename": "I18nString"
        },
        {
          "stringValue": "Waterway",
          "language": "en",
          "__typename": "I18nString"
        }
      ],
      "__typename": "GeometryCategory"
    },
    "__typename": "GeospatialObjectCategoryTreeLevel"
  },
  {
    "id": 21,
    "color": "#B538D5",
    "positionInTree": [
      1,
      2,
      4
    ],
    "level": 3,
    "parentId": 7,
    "childrenIds": [],
    "i18nStrings": [
      {
        "stringValue": "Instalação Portuária de Turismo",
        "language": "pt-BR",
        "__typename": "I18nString"
      },
      {
        "stringValue": "Port Facility for Tourism",
        "language": "en",
        "__typename": "I18nString"
      }
    ],
    "geometryCategory": {
      "i18nStrings": [
        {
          "stringValue": "Instalação Portuária de Turismo",
          "language": "pt-BR",
          "__typename": "I18nString"
        },
        {
          "stringValue": "Port Facility for Tourism",
          "language": "en",
          "__typename": "I18nString"
        }
      ],
      "__typename": "GeometryCategory"
    },
    "__typename": "GeospatialObjectCategoryTreeLevel"
  },
  {
    "id": 22,
    "color": "#C70324",
    "positionInTree": [
      1,
      2,
      5
    ],
    "level": 3,
    "parentId": 7,
    "childrenIds": [],
    "i18nStrings": [
      {
        "stringValue": "Instalação Portuária sob Registro",
        "language": "pt-BR",
        "__typename": "I18nString"
      },
      {
        "stringValue": "Registered Port Facility",
        "language": "en",
        "__typename": "I18nString"
      }
    ],
    "geometryCategory": {
      "i18nStrings": [
        {
          "stringValue": "Instalação Portuária sob Registro",
          "language": "pt-BR",
          "__typename": "I18nString"
        },
        {
          "stringValue": "Registered Port Facility",
          "language": "en",
          "__typename": "I18nString"
        }
      ],
      "__typename": "GeometryCategory"
    },
    "__typename": "GeospatialObjectCategoryTreeLevel"
  },
  {
    "id": 10,
    "color": "#37BE65",
    "positionInTree": [
      1,
      2,
      6
    ],
    "level": 3,
    "parentId": 7,
    "childrenIds": [],
    "i18nStrings": [
      {
        "stringValue": "Porto Organizado",
        "language": "pt-BR",
        "__typename": "I18nString"
      },
      {
        "stringValue": "Organized Port",
        "language": "en",
        "__typename": "I18nString"
      }
    ],
    "geometryCategory": {
      "i18nStrings": [
        {
          "stringValue": "Porto Organizado",
          "language": "pt-BR",
          "__typename": "I18nString"
        },
        {
          "stringValue": "Organized Port",
          "language": "en",
          "__typename": "I18nString"
        }
      ],
      "__typename": "GeometryCategory"
    },
    "__typename": "GeospatialObjectCategoryTreeLevel"
  },
  {
    "id": 29,
    "color": "#583B18",
    "positionInTree": [
      1,
      2,
      7
    ],
    "level": 3,
    "parentId": 7,
    "childrenIds": [],
    "i18nStrings": [
      {
        "stringValue": "Terminal de Uso Privado",
        "language": "pt-BR",
        "__typename": "I18nString"
      },
      {
        "stringValue": "Private Use Terminal",
        "language": "en",
        "__typename": "I18nString"
      }
    ],
    "geometryCategory": {
      "i18nStrings": [
        {
          "stringValue": "Terminal de Uso Privado",
          "language": "pt-BR",
          "__typename": "I18nString"
        },
        {
          "stringValue": "Private Use Terminal",
          "language": "en",
          "__typename": "I18nString"
        }
      ],
      "__typename": "GeometryCategory"
    },
    "__typename": "GeospatialObjectCategoryTreeLevel"
  },
  {
    "id": 63,
    "color": "#DFDCFD",
    "positionInTree": [
      1,
      2,
      8
    ],
    "level": 3,
    "parentId": 7,
    "childrenIds": [],
    "i18nStrings": [
      {
        "stringValue": "Outras Instalações Portuárias",
        "language": "pt-BR",
        "__typename": "I18nString"
      },
      {
        "stringValue": "Other port facilities",
        "language": "en",
        "__typename": "I18nString"
      }
    ],
    "geometryCategory": {
      "i18nStrings": [
        {
          "stringValue": "Outras Instalações Portuárias",
          "language": "pt-BR",
          "__typename": "I18nString"
        },
        {
          "stringValue": "Other port facilities",
          "language": "en",
          "__typename": "I18nString"
        }
      ],
      "__typename": "GeometryCategory"
    },
    "__typename": "GeospatialObjectCategoryTreeLevel"
  },
  {
    "id": 8,
    "color": null,
    "positionInTree": [
      1,
      3
    ],
    "level": 2,
    "parentId": 1,
    "childrenIds": [
      31,
      32,
      33,
      34,
      35,
      36
    ],
    "i18nStrings": [
      {
        "stringValue": "Dutoviário",
        "language": "pt-BR",
        "__typename": "I18nString"
      },
      {
        "stringValue": "Pipeline",
        "language": "en",
        "__typename": "I18nString"
      }
    ],
    "geometryCategory": null,
    "__typename": "GeospatialObjectCategoryTreeLevel"
  },
  {
    "id": 11,
    "color": "#7DE60E",
    "positionInTree": [
      1,
      3,
      1
    ],
    "level": 3,
    "parentId": 8,
    "childrenIds": [],
    "i18nStrings": [
      {
        "stringValue": "Aquaduto",
        "language": "pt-BR",
        "__typename": "I18nString"
      },
      {
        "stringValue": "Aqueduct",
        "language": "en",
        "__typename": "I18nString"
      }
    ],
    "geometryCategory": {
      "i18nStrings": [
        {
          "stringValue": "Aquaduto",
          "language": "pt-BR",
          "__typename": "I18nString"
        },
        {
          "stringValue": "Aqueduct",
          "language": "en",
          "__typename": "I18nString"
        }
      ],
      "__typename": "GeometryCategory"
    },
    "__typename": "GeospatialObjectCategoryTreeLevel"
  },
  {
    "id": 32,
    "color": null,
    "positionInTree": [
      1,
      3,
      2
    ],
    "level": 3,
    "parentId": 8,
    "childrenIds": [
      45,
      46,
      47
    ],
    "i18nStrings": [
      {
        "stringValue": "Gasodutos",
        "language": "pt-BR",
        "__typename": "I18nString"
      },
      {
        "stringValue": "Gas Pipeline",
        "language": "en",
        "__typename": "I18nString"
      }
    ],
    "geometryCategory": null,
    "__typename": "GeospatialObjectCategoryTreeLevel"
  },
  {
    "id": 64,
    "color": "#4BA681",
    "positionInTree": [
      1,
      3,
      2,
      1
    ],
    "level": 4,
    "parentId": 32,
    "childrenIds": [],
    "i18nStrings": [
      {
        "stringValue": "Gasoduto de Transporte",
        "language": "pt-BR",
        "__typename": "I18nString"
      },
      {
        "stringValue": "Transportation gas Pipeline",
        "language": "en",
        "__typename": "I18nString"
      }
    ],
    "geometryCategory": {
      "i18nStrings": [
        {
          "stringValue": "Gasoduto de Transporte",
          "language": "pt-BR",
          "__typename": "I18nString"
        },
        {
          "stringValue": "Transportation gas Pipeline",
          "language": "en",
          "__typename": "I18nString"
        }
      ],
      "__typename": "GeometryCategory"
    },
    "__typename": "GeospatialObjectCategoryTreeLevel"
  },
  // {
  //   "id": null,
  //   "color": "#75E0E0",
  //   "positionInTree": [
  //     1,
  //     3,
  //     2,
  //     2
  //   ],
  //   "level": 4,
  //   "parentId": 32,
  //   "childrenIds": [],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Gasoduto de distribuição",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Distribution gas pipeline",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": {
  //     "i18nStrings": [
  //       {
  //         "stringValue": "Gasoduto de distribuição",
  //         "language": "pt-BR",
  //         "__typename": "I18nString"
  //       },
  //       {
  //         "stringValue": "Distribution gas pipeline",
  //         "language": "en",
  //         "__typename": "I18nString"
  //       }
  //     ],
  //     "__typename": "GeometryCategory"
  //   },
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  {
    "id": 66,
    "color": "#9A946D",
    "positionInTree": [
      1,
      3,
      2,
      3
    ],
    "level": 4,
    "parentId": 32,
    "childrenIds": [],
    "i18nStrings": [
      {
        "stringValue": "Gasoduto de escoamento",
        "language": "pt-BR",
        "__typename": "I18nString"
      },
      {
        "stringValue": "Outflow gas Pipeline",
        "language": "en",
        "__typename": "I18nString"
      }
    ],
    "geometryCategory": {
      "i18nStrings": [
        {
          "stringValue": "Gasoduto de escoamento",
          "language": "pt-BR",
          "__typename": "I18nString"
        },
        {
          "stringValue": "Outflow gas Pipeline",
          "language": "en",
          "__typename": "I18nString"
        }
      ],
      "__typename": "GeometryCategory"
    },
    "__typename": "GeospatialObjectCategoryTreeLevel"
  },
  {
    "id": 23,
    "color": "#A385AD",
    "positionInTree": [
      1,
      3,
      3
    ],
    "level": 3,
    "parentId": 8,
    "childrenIds": [],
    "i18nStrings": [
      {
        "stringValue": "Mineroduto",
        "language": "pt-BR",
        "__typename": "I18nString"
      },
      {
        "stringValue": "Mining pipeline",
        "language": "en",
        "__typename": "I18nString"
      }
    ],
    "geometryCategory": {
      "i18nStrings": [
        {
          "stringValue": "Mineroduto",
          "language": "pt-BR",
          "__typename": "I18nString"
        },
        {
          "stringValue": "Mining pipeline",
          "language": "en",
          "__typename": "I18nString"
        }
      ],
      "__typename": "GeometryCategory"
    },
    "__typename": "GeospatialObjectCategoryTreeLevel"
  },
  {
    "id": 25,
    "color": "#E72EA7",
    "positionInTree": [
      1,
      3,
      4
    ],
    "level": 3,
    "parentId": 8,
    "childrenIds": [],
    "i18nStrings": [
      {
        "stringValue": "Oleoduto",
        "language": "pt-BR",
        "__typename": "I18nString"
      },
      {
        "stringValue": "Oil Pipeline",
        "language": "en",
        "__typename": "I18nString"
      }
    ],
    "geometryCategory": {
      "i18nStrings": [
        {
          "stringValue": "Oleoduto",
          "language": "pt-BR",
          "__typename": "I18nString"
        },
        {
          "stringValue": "Oil Pipeline",
          "language": "en",
          "__typename": "I18nString"
        }
      ],
      "__typename": "GeometryCategory"
    },
    "__typename": "GeospatialObjectCategoryTreeLevel"
  },
  {
    "id": 26,
    "color": "#AE2568",
    "positionInTree": [
      1,
      3,
      5
    ],
    "level": 3,
    "parentId": 8,
    "childrenIds": [],
    "i18nStrings": [
      {
        "stringValue": "Poliduto",
        "language": "pt-BR",
        "__typename": "I18nString"
      },
      {
        "stringValue": "Polyduct",
        "language": "en",
        "__typename": "I18nString"
      }
    ],
    "geometryCategory": {
      "i18nStrings": [
        {
          "stringValue": "Poliduto",
          "language": "pt-BR",
          "__typename": "I18nString"
        },
        {
          "stringValue": "Polyduct",
          "language": "en",
          "__typename": "I18nString"
        }
      ],
      "__typename": "GeometryCategory"
    },
    "__typename": "GeospatialObjectCategoryTreeLevel"
  },
  {
    "id": 24,
    "color": "#DAB03A",
    "positionInTree": [
      1,
      3,
      6
    ],
    "level": 3,
    "parentId": 8,
    "childrenIds": [],
    "i18nStrings": [
      {
        "stringValue": "Não Identificado",
        "language": "pt-BR",
        "__typename": "I18nString"
      },
      {
        "stringValue": "Not Identified",
        "language": "en",
        "__typename": "I18nString"
      }
    ],
    "geometryCategory": {
      "i18nStrings": [
        {
          "stringValue": "Não Identificado",
          "language": "pt-BR",
          "__typename": "I18nString"
        },
        {
          "stringValue": "Not Identified",
          "language": "en",
          "__typename": "I18nString"
        }
      ],
      "__typename": "GeometryCategory"
    },
    "__typename": "GeospatialObjectCategoryTreeLevel"
  },
  {
    "id": 54,
    "color": "#A842EB",
    "positionInTree": [
      1,
      4
    ],
    "level": 2,
    "parentId": 1,
    "childrenIds": [],
    "i18nStrings": [
      {
        "stringValue": "Ferroviário",
        "language": "pt-BR",
        "__typename": "I18nString"
      },
      {
        "stringValue": "Railroad",
        "language": "en",
        "__typename": "I18nString"
      }
    ],
    "geometryCategory": {
      "i18nStrings": [
        {
          "stringValue": "Ferroviário",
          "language": "pt-BR",
          "__typename": "I18nString"
        },
        {
          "stringValue": "Railroad",
          "language": "en",
          "__typename": "I18nString"
        }
      ],
      "__typename": "GeometryCategory"
    },
    "__typename": "GeospatialObjectCategoryTreeLevel"
  },
  {
    "id": 10,
    "color": null,
    "positionInTree": [
      1,
      5
    ],
    "level": 2,
    "parentId": 1,
    "childrenIds": [
      37,
      38,
      39
    ],
    "i18nStrings": [
      {
        "stringValue": "Rodoviário",
        "language": "pt-BR",
        "__typename": "I18nString"
      },
      {
        "stringValue": "Highway",
        "language": "en",
        "__typename": "I18nString"
      }
    ],
    "geometryCategory": null,
    "__typename": "GeospatialObjectCategoryTreeLevel"
  },
  {
    "id": 27,
    "color": "#55577F",
    "positionInTree": [
      1,
      5,
      1
    ],
    "level": 3,
    "parentId": 10,
    "childrenIds": [],
    "i18nStrings": [
      {
        "stringValue": "Rodovia Estadual",
        "language": "pt-BR",
        "__typename": "I18nString"
      },
      {
        "stringValue": "State Highway",
        "language": "en",
        "__typename": "I18nString"
      }
    ],
    "geometryCategory": {
      "i18nStrings": [
        {
          "stringValue": "Rodovia Estadual",
          "language": "pt-BR",
          "__typename": "I18nString"
        },
        {
          "stringValue": "State Highway",
          "language": "en",
          "__typename": "I18nString"
        }
      ],
      "__typename": "GeometryCategory"
    },
    "__typename": "GeospatialObjectCategoryTreeLevel"
  },
  {
    "id": 28,
    "color": "#7CF14C",
    "positionInTree": [
      1,
      5,
      2
    ],
    "level": 3,
    "parentId": 10,
    "childrenIds": [],
    "i18nStrings": [
      {
        "stringValue": "Rodovia Federal",
        "language": "pt-BR",
        "__typename": "I18nString"
      },
      {
        "stringValue": "Federal Highway",
        "language": "en",
        "__typename": "I18nString"
      }
    ],
    "geometryCategory": {
      "i18nStrings": [
        {
          "stringValue": "Rodovia Federal",
          "language": "pt-BR",
          "__typename": "I18nString"
        },
        {
          "stringValue": "Federal Highway",
          "language": "en",
          "__typename": "I18nString"
        }
      ],
      "__typename": "GeometryCategory"
    },
    "__typename": "GeospatialObjectCategoryTreeLevel"
  },
  // {
  //   "id": null,
  //   "color": "#58BC66",
  //   "positionInTree": [
  //     1,
  //     5,
  //     3
  //   ],
  //   "level": 3,
  //   "parentId": 10,
  //   "childrenIds": [],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Outros trechos rodoviários",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Other road sections",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": {
  //     "i18nStrings": [
  //       {
  //         "stringValue": "Outros trechos rodoviários",
  //         "language": "pt-BR",
  //         "__typename": "I18nString"
  //       },
  //       {
  //         "stringValue": "Other road sections",
  //         "language": "en",
  //         "__typename": "I18nString"
  //       }
  //     ],
  //     "__typename": "GeometryCategory"
  //   },
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 2,
  //   "color": null,
  //   "positionInTree": [
  //     2
  //   ],
  //   "level": 1,
  //   "parentId": null,
  //   "childrenIds": [
  //     11,
  //     12
  //   ],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Energia",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Energy",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": null,
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 11,
  //   "color": null,
  //   "positionInTree": [
  //     2,
  //     1
  //   ],
  //   "level": 2,
  //   "parentId": 2,
  //   "childrenIds": [
  //     40,
  //     41,
  //     42
  //   ],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Combustíveis",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Fuels",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": null,
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 40,
  //   "color": null,
  //   "positionInTree": [
  //     2,
  //     1,
  //     1
  //   ],
  //   "level": 3,
  //   "parentId": 11,
  //   "childrenIds": [
  //     48,
  //     49
  //   ],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Combustíveis Fósseis",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Fossil Fuels",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": null,
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 48,
  //   "color": null,
  //   "positionInTree": [
  //     2,
  //     1,
  //     1,
  //     1
  //   ],
  //   "level": 4,
  //   "parentId": 40,
  //   "childrenIds": [
  //     59,
  //     60
  //   ],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Combustíveis Líquidos",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Liquid Fuels",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": null,
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 59,
  //   "color": "#B91CE2",
  //   "positionInTree": [
  //     2,
  //     1,
  //     1,
  //     1,
  //     1
  //   ],
  //   "level": 5,
  //   "parentId": 48,
  //   "childrenIds": [],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Refinarias de Petróleo e Outras Instalações de Processamento",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Oil Refineries and Other Processing Facilities",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": {
  //     "i18nStrings": [
  //       {
  //         "stringValue": "Refinarias de Petróleo e Outras Instalações de Processamento",
  //         "language": "pt-BR",
  //         "__typename": "I18nString"
  //       },
  //       {
  //         "stringValue": "Oil Refineries and Other Processing Facilities",
  //         "language": "en",
  //         "__typename": "I18nString"
  //       }
  //     ],
  //     "__typename": "GeometryCategory"
  //   },
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 60,
  //   "color": "#03AB71",
  //   "positionInTree": [
  //     2,
  //     1,
  //     1,
  //     1,
  //     2
  //   ],
  //   "level": 5,
  //   "parentId": 48,
  //   "childrenIds": [],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Terminal de Armazenamento e Distribuição de Petróleo e Derivados",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Oil and Oil Products Storage and Distribution Terminal",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": {
  //     "i18nStrings": [
  //       {
  //         "stringValue": "Terminal de Armazenamento e Distribuição de Petróleo e Derivados",
  //         "language": "pt-BR",
  //         "__typename": "I18nString"
  //       },
  //       {
  //         "stringValue": "Oil and Oil Products Storage and Distribution Terminal",
  //         "language": "en",
  //         "__typename": "I18nString"
  //       }
  //     ],
  //     "__typename": "GeometryCategory"
  //   },
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 49,
  //   "color": null,
  //   "positionInTree": [
  //     2,
  //     1,
  //     1,
  //     2
  //   ],
  //   "level": 4,
  //   "parentId": 40,
  //   "childrenIds": [
  //     61,
  //     62,
  //     63,
  //     64
  //   ],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Gás",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Gas",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": null,
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 61,
  //   "color": "#B6A57A",
  //   "positionInTree": [
  //     2,
  //     1,
  //     1,
  //     2,
  //     1
  //   ],
  //   "level": 5,
  //   "parentId": 49,
  //   "childrenIds": [],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Estação de Compressão",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Compression Station",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": {
  //     "i18nStrings": [
  //       {
  //         "stringValue": "Estação de Compressão",
  //         "language": "pt-BR",
  //         "__typename": "I18nString"
  //       },
  //       {
  //         "stringValue": "Compression Station",
  //         "language": "en",
  //         "__typename": "I18nString"
  //       }
  //     ],
  //     "__typename": "GeometryCategory"
  //   },
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 62,
  //   "color": "#8358FD",
  //   "positionInTree": [
  //     2,
  //     1,
  //     1,
  //     2,
  //     2
  //   ],
  //   "level": 5,
  //   "parentId": 49,
  //   "childrenIds": [],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Ponto de Entrega de Gás",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Gas Delivery Point",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": {
  //     "i18nStrings": [
  //       {
  //         "stringValue": "Ponto de Entrega de Gás",
  //         "language": "pt-BR",
  //         "__typename": "I18nString"
  //       },
  //       {
  //         "stringValue": "Gas Delivery Point",
  //         "language": "en",
  //         "__typename": "I18nString"
  //       }
  //     ],
  //     "__typename": "GeometryCategory"
  //   },
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 63,
  //   "color": "#6C2472",
  //   "positionInTree": [
  //     2,
  //     1,
  //     1,
  //     2,
  //     3
  //   ],
  //   "level": 5,
  //   "parentId": 49,
  //   "childrenIds": [],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Unidade de Processamento de Gás Natural",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Natural Gas Processing Unit",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": {
  //     "i18nStrings": [
  //       {
  //         "stringValue": "Unidade de Processamento de Gás Natural",
  //         "language": "pt-BR",
  //         "__typename": "I18nString"
  //       },
  //       {
  //         "stringValue": "Natural Gas Processing Unit",
  //         "language": "en",
  //         "__typename": "I18nString"
  //       }
  //     ],
  //     "__typename": "GeometryCategory"
  //   },
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 64,
  //   "color": "#BFCD19",
  //   "positionInTree": [
  //     2,
  //     1,
  //     1,
  //     2,
  //     4
  //   ],
  //   "level": 5,
  //   "parentId": 49,
  //   "childrenIds": [],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Terminais de Gás Natural Liquefeito",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Liquefied Natural Gas Terminals",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": {
  //     "i18nStrings": [
  //       {
  //         "stringValue": "Terminais de Gás Natural Liquefeito",
  //         "language": "pt-BR",
  //         "__typename": "I18nString"
  //       },
  //       {
  //         "stringValue": "Liquefied Natural Gas Terminals",
  //         "language": "en",
  //         "__typename": "I18nString"
  //       }
  //     ],
  //     "__typename": "GeometryCategory"
  //   },
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 41,
  //   "color": null,
  //   "positionInTree": [
  //     2,
  //     1,
  //     2
  //   ],
  //   "level": 3,
  //   "parentId": 11,
  //   "childrenIds": [
  //     50,
  //     51,
  //     52
  //   ],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Combustíveis Renováveis",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Renewable Fuels",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": null,
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 50,
  //   "color": "#95EC4D",
  //   "positionInTree": [
  //     2,
  //     1,
  //     2,
  //     1
  //   ],
  //   "level": 4,
  //   "parentId": 41,
  //   "childrenIds": [],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Usina de Biodiesel",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Biodiesel Plant",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": {
  //     "i18nStrings": [
  //       {
  //         "stringValue": "Usina de Biodiesel",
  //         "language": "pt-BR",
  //         "__typename": "I18nString"
  //       },
  //       {
  //         "stringValue": "Biodiesel Plant",
  //         "language": "en",
  //         "__typename": "I18nString"
  //       }
  //     ],
  //     "__typename": "GeometryCategory"
  //   },
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 51,
  //   "color": "#92C9F8",
  //   "positionInTree": [
  //     2,
  //     1,
  //     2,
  //     2
  //   ],
  //   "level": 4,
  //   "parentId": 41,
  //   "childrenIds": [],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Usina de Biogás",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Biogas Plant",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": {
  //     "i18nStrings": [
  //       {
  //         "stringValue": "Usina de Biogás",
  //         "language": "pt-BR",
  //         "__typename": "I18nString"
  //       },
  //       {
  //         "stringValue": "Biogas Plant",
  //         "language": "en",
  //         "__typename": "I18nString"
  //       }
  //     ],
  //     "__typename": "GeometryCategory"
  //   },
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 52,
  //   "color": "#E68168",
  //   "positionInTree": [
  //     2,
  //     1,
  //     2,
  //     3
  //   ],
  //   "level": 4,
  //   "parentId": 41,
  //   "childrenIds": [],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Usina de Etanol",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Ethanol Plant",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": {
  //     "i18nStrings": [
  //       {
  //         "stringValue": "Usina de Etanol",
  //         "language": "pt-BR",
  //         "__typename": "I18nString"
  //       },
  //       {
  //         "stringValue": "Ethanol Plant",
  //         "language": "en",
  //         "__typename": "I18nString"
  //       }
  //     ],
  //     "__typename": "GeometryCategory"
  //   },
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 42,
  //   "color": null,
  //   "positionInTree": [
  //     2,
  //     1,
  //     3
  //   ],
  //   "level": 3,
  //   "parentId": 11,
  //   "childrenIds": [
  //     53,
  //     54
  //   ],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Bases de Distribuição",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Distribution Bases",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": null,
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 53,
  //   "color": "#218D53",
  //   "positionInTree": [
  //     2,
  //     1,
  //     3,
  //     1
  //   ],
  //   "level": 4,
  //   "parentId": 42,
  //   "childrenIds": [],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Base de Gás Liquefeito de Petróleo",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Liquefied Petroleum Gas Base",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": {
  //     "i18nStrings": [
  //       {
  //         "stringValue": "Base de Gás Liquefeito de Petróleo",
  //         "language": "pt-BR",
  //         "__typename": "I18nString"
  //       },
  //       {
  //         "stringValue": "Liquefied Petroleum Gas Base",
  //         "language": "en",
  //         "__typename": "I18nString"
  //       }
  //     ],
  //     "__typename": "GeometryCategory"
  //   },
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 54,
  //   "color": "#69BE32",
  //   "positionInTree": [
  //     2,
  //     1,
  //     3,
  //     2
  //   ],
  //   "level": 4,
  //   "parentId": 42,
  //   "childrenIds": [],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Base de Combustíveis Líquidos",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Liquid Fuel Base",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": {
  //     "i18nStrings": [
  //       {
  //         "stringValue": "Base de Combustíveis Líquidos",
  //         "language": "pt-BR",
  //         "__typename": "I18nString"
  //       },
  //       {
  //         "stringValue": "Liquid Fuel Base",
  //         "language": "en",
  //         "__typename": "I18nString"
  //       }
  //     ],
  //     "__typename": "GeometryCategory"
  //   },
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 12,
  //   "color": null,
  //   "positionInTree": [
  //     2,
  //     2
  //   ],
  //   "level": 2,
  //   "parentId": 2,
  //   "childrenIds": [
  //     43,
  //     44
  //   ],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Setor Elétrico",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Power Sector",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": null,
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 43,
  //   "color": null,
  //   "positionInTree": [
  //     2,
  //     2,
  //     1
  //   ],
  //   "level": 3,
  //   "parentId": 12,
  //   "childrenIds": [
  //     55,
  //     56
  //   ],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Infraestrutura",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Infrastructure",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": null,
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 55,
  //   "color": "#8A8E22",
  //   "positionInTree": [
  //     2,
  //     2,
  //     1,
  //     1
  //   ],
  //   "level": 4,
  //   "parentId": 43,
  //   "childrenIds": [],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Subestação",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Substation",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": {
  //     "i18nStrings": [
  //       {
  //         "stringValue": "Subestação",
  //         "language": "pt-BR",
  //         "__typename": "I18nString"
  //       },
  //       {
  //         "stringValue": "Substation",
  //         "language": "en",
  //         "__typename": "I18nString"
  //       }
  //     ],
  //     "__typename": "GeometryCategory"
  //   },
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 56,
  //   "color": "#136FEA",
  //   "positionInTree": [
  //     2,
  //     2,
  //     1,
  //     2
  //   ],
  //   "level": 4,
  //   "parentId": 43,
  //   "childrenIds": [],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Linha de Transmissão",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Transmission Line",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": {
  //     "i18nStrings": [
  //       {
  //         "stringValue": "Linha de Transmissão",
  //         "language": "pt-BR",
  //         "__typename": "I18nString"
  //       },
  //       {
  //         "stringValue": "Transmission Line",
  //         "language": "en",
  //         "__typename": "I18nString"
  //       }
  //     ],
  //     "__typename": "GeometryCategory"
  //   },
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 44,
  //   "color": null,
  //   "positionInTree": [
  //     2,
  //     2,
  //     2
  //   ],
  //   "level": 3,
  //   "parentId": 12,
  //   "childrenIds": [
  //     57,
  //     58
  //   ],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Geração",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Generation",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": null,
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 57,
  //   "color": null,
  //   "positionInTree": [
  //     2,
  //     2,
  //     2,
  //     1
  //   ],
  //   "level": 4,
  //   "parentId": 44,
  //   "childrenIds": [
  //     65,
  //     66,
  //     67,
  //     68,
  //     69,
  //     70
  //   ],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Geração Renovável",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Renewable Generation",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": null,
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 65,
  //   "color": "#EBD5C5",
  //   "positionInTree": [
  //     2,
  //     2,
  //     2,
  //     1,
  //     1
  //   ],
  //   "level": 5,
  //   "parentId": 57,
  //   "childrenIds": [],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Usina Eólica",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Wind Power Plant",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": {
  //     "i18nStrings": [
  //       {
  //         "stringValue": "Usina Eólica",
  //         "language": "pt-BR",
  //         "__typename": "I18nString"
  //       },
  //       {
  //         "stringValue": "Wind Power Plant",
  //         "language": "en",
  //         "__typename": "I18nString"
  //       }
  //     ],
  //     "__typename": "GeometryCategory"
  //   },
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 66,
  //   "color": "#B5A50C",
  //   "positionInTree": [
  //     2,
  //     2,
  //     2,
  //     1,
  //     2
  //   ],
  //   "level": 5,
  //   "parentId": 57,
  //   "childrenIds": [],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Usina Solar Fotovoltaica",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Photovoltaic Solar Power Plant",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": {
  //     "i18nStrings": [
  //       {
  //         "stringValue": "Usina Solar Fotovoltaica",
  //         "language": "pt-BR",
  //         "__typename": "I18nString"
  //       },
  //       {
  //         "stringValue": "Photovoltaic Solar Power Plant",
  //         "language": "en",
  //         "__typename": "I18nString"
  //       }
  //     ],
  //     "__typename": "GeometryCategory"
  //   },
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 67,
  //   "color": "#C421F9",
  //   "positionInTree": [
  //     2,
  //     2,
  //     2,
  //     1,
  //     3
  //   ],
  //   "level": 5,
  //   "parentId": 57,
  //   "childrenIds": [],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Usina Termelétrica a Biomassa",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Biomass Thermal Power Plant",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": {
  //     "i18nStrings": [
  //       {
  //         "stringValue": "Usina Termelétrica a Biomassa",
  //         "language": "pt-BR",
  //         "__typename": "I18nString"
  //       },
  //       {
  //         "stringValue": "Biomass Thermal Power Plant",
  //         "language": "en",
  //         "__typename": "I18nString"
  //       }
  //     ],
  //     "__typename": "GeometryCategory"
  //   },
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 68,
  //   "color": "#E7F6DD",
  //   "positionInTree": [
  //     2,
  //     2,
  //     2,
  //     1,
  //     4
  //   ],
  //   "level": 5,
  //   "parentId": 57,
  //   "childrenIds": [],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Usina Hidrelétrica",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Hydroelectric Power Plant",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": {
  //     "i18nStrings": [
  //       {
  //         "stringValue": "Usina Hidrelétrica",
  //         "language": "pt-BR",
  //         "__typename": "I18nString"
  //       },
  //       {
  //         "stringValue": "Hydroelectric Power Plant",
  //         "language": "en",
  //         "__typename": "I18nString"
  //       }
  //     ],
  //     "__typename": "GeometryCategory"
  //   },
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 69,
  //   "color": "#1087B2",
  //   "positionInTree": [
  //     2,
  //     2,
  //     2,
  //     1,
  //     5
  //   ],
  //   "level": 5,
  //   "parentId": 57,
  //   "childrenIds": [],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Pequena Central Hidrelétrica",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Small Hydropower Plant",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": {
  //     "i18nStrings": [
  //       {
  //         "stringValue": "Pequena Central Hidrelétrica",
  //         "language": "pt-BR",
  //         "__typename": "I18nString"
  //       },
  //       {
  //         "stringValue": "Small Hydropower Plant",
  //         "language": "en",
  //         "__typename": "I18nString"
  //       }
  //     ],
  //     "__typename": "GeometryCategory"
  //   },
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 70,
  //   "color": "#8A3C37",
  //   "positionInTree": [
  //     2,
  //     2,
  //     2,
  //     1,
  //     6
  //   ],
  //   "level": 5,
  //   "parentId": 57,
  //   "childrenIds": [],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Central Geradora Hidrelétrica",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Hydroelectric Generating Centers",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": {
  //     "i18nStrings": [
  //       {
  //         "stringValue": "Central Geradora Hidrelétrica",
  //         "language": "pt-BR",
  //         "__typename": "I18nString"
  //       },
  //       {
  //         "stringValue": "Hydroelectric Generating Centers",
  //         "language": "en",
  //         "__typename": "I18nString"
  //       }
  //     ],
  //     "__typename": "GeometryCategory"
  //   },
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 58,
  //   "color": null,
  //   "positionInTree": [
  //     2,
  //     2,
  //     2,
  //     2
  //   ],
  //   "level": 4,
  //   "parentId": 44,
  //   "childrenIds": [
  //     71,
  //     72
  //   ],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Geração Não-Renovável",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Non-Renewable Generation",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": null,
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 71,
  //   "color": "#B1486D",
  //   "positionInTree": [
  //     2,
  //     2,
  //     2,
  //     2,
  //     1
  //   ],
  //   "level": 5,
  //   "parentId": 58,
  //   "childrenIds": [],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Usina Termelétrica a Combustível Fóssil",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Fossil Fuel Thermal Power Plant",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": {
  //     "i18nStrings": [
  //       {
  //         "stringValue": "Usina Termelétrica a Combustível Fóssil",
  //         "language": "pt-BR",
  //         "__typename": "I18nString"
  //       },
  //       {
  //         "stringValue": "Fossil Fuel Thermal Power Plant",
  //         "language": "en",
  //         "__typename": "I18nString"
  //       }
  //     ],
  //     "__typename": "GeometryCategory"
  //   },
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 72,
  //   "color": "#B3778D",
  //   "positionInTree": [
  //     2,
  //     2,
  //     2,
  //     2,
  //     2
  //   ],
  //   "level": 5,
  //   "parentId": 58,
  //   "childrenIds": [],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Usina Termonuclear",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Thermonuclear Power Plant",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": {
  //     "i18nStrings": [
  //       {
  //         "stringValue": "Usina Termonuclear",
  //         "language": "pt-BR",
  //         "__typename": "I18nString"
  //       },
  //       {
  //         "stringValue": "Thermonuclear Power Plant",
  //         "language": "en",
  //         "__typename": "I18nString"
  //       }
  //     ],
  //     "__typename": "GeometryCategory"
  //   },
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 3,
  //   "color": null,
  //   "positionInTree": [
  //     3
  //   ],
  //   "level": 1,
  //   "parentId": null,
  //   "childrenIds": [
  //     13,
  //     14,
  //     15,
  //     16
  //   ],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Mineração",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Mining",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": null,
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 13,
  //   "color": "#4F259A",
  //   "positionInTree": [
  //     3,
  //     1
  //   ],
  //   "level": 2,
  //   "parentId": 3,
  //   "childrenIds": [],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Mina de Produto Energético",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Energetic Product Mine",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": {
  //     "i18nStrings": [
  //       {
  //         "stringValue": "Mina de Produto Energético",
  //         "language": "pt-BR",
  //         "__typename": "I18nString"
  //       },
  //       {
  //         "stringValue": "Energetic Product Mine",
  //         "language": "en",
  //         "__typename": "I18nString"
  //       }
  //     ],
  //     "__typename": "GeometryCategory"
  //   },
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 14,
  //   "color": "#1F69D0",
  //   "positionInTree": [
  //     3,
  //     2
  //   ],
  //   "level": 2,
  //   "parentId": 3,
  //   "childrenIds": [],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Mina de Produto Metálico",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Metallic Product Mine",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": {
  //     "i18nStrings": [
  //       {
  //         "stringValue": "Mina de Produto Metálico",
  //         "language": "pt-BR",
  //         "__typename": "I18nString"
  //       },
  //       {
  //         "stringValue": "Metallic Product Mine",
  //         "language": "en",
  //         "__typename": "I18nString"
  //       }
  //     ],
  //     "__typename": "GeometryCategory"
  //   },
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 15,
  //   "color": "#AEEB6F",
  //   "positionInTree": [
  //     3,
  //     3
  //   ],
  //   "level": 2,
  //   "parentId": 3,
  //   "childrenIds": [],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Mina de Outros Produtos",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Other Products Mine",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": {
  //     "i18nStrings": [
  //       {
  //         "stringValue": "Mina de Outros Produtos",
  //         "language": "pt-BR",
  //         "__typename": "I18nString"
  //       },
  //       {
  //         "stringValue": "Other Products Mine",
  //         "language": "en",
  //         "__typename": "I18nString"
  //       }
  //     ],
  //     "__typename": "GeometryCategory"
  //   },
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 16,
  //   "color": "#A0C6C5",
  //   "positionInTree": [
  //     3,
  //     4
  //   ],
  //   "level": 2,
  //   "parentId": 3,
  //   "childrenIds": [],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Barragens",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Mining Dam",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": {
  //     "i18nStrings": [
  //       {
  //         "stringValue": "Barragens",
  //         "language": "pt-BR",
  //         "__typename": "I18nString"
  //       },
  //       {
  //         "stringValue": "Mining Dam",
  //         "language": "en",
  //         "__typename": "I18nString"
  //       }
  //     ],
  //     "__typename": "GeometryCategory"
  //   },
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 4,
  //   "color": null,
  //   "positionInTree": [
  //     4
  //   ],
  //   "level": 1,
  //   "parentId": null,
  //   "childrenIds": [
  //     17,
  //     18
  //   ],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Agronegócio",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Agribusiness",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": null,
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 17,
  //   "color": "#A8E0CF",
  //   "positionInTree": [
  //     4,
  //     1
  //   ],
  //   "level": 2,
  //   "parentId": 4,
  //   "childrenIds": [],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Armazens e silos",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Warehouses",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": {
  //     "i18nStrings": [
  //       {
  //         "stringValue": "Armazens e silos",
  //         "language": "pt-BR",
  //         "__typename": "I18nString"
  //       },
  //       {
  //         "stringValue": "Warehouses",
  //         "language": "en",
  //         "__typename": "I18nString"
  //       }
  //     ],
  //     "__typename": "GeometryCategory"
  //   },
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 18,
  //   "color": "#4B4616",
  //   "positionInTree": [
  //     4,
  //     2
  //   ],
  //   "level": 2,
  //   "parentId": 4,
  //   "childrenIds": [],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Frigoríficos",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Slaughterhouse",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": {
  //     "i18nStrings": [
  //       {
  //         "stringValue": "Frigoríficos",
  //         "language": "pt-BR",
  //         "__typename": "I18nString"
  //       },
  //       {
  //         "stringValue": "Slaughterhouse",
  //         "language": "en",
  //         "__typename": "I18nString"
  //       }
  //     ],
  //     "__typename": "GeometryCategory"
  //   },
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 5,
  //   "color": null,
  //   "positionInTree": [
  //     5
  //   ],
  //   "level": 1,
  //   "parentId": null,
  //   "childrenIds": [
  //     19
  //   ],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Telecomunicação",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Telecommunication",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": null,
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // },
  // {
  //   "id": 19,
  //   "color": "#01A755",
  //   "positionInTree": [
  //     5,
  //     1
  //   ],
  //   "level": 2,
  //   "parentId": 5,
  //   "childrenIds": [],
  //   "i18nStrings": [
  //     {
  //       "stringValue": "Estações de Rádio Base (ERBs)",
  //       "language": "pt-BR",
  //       "__typename": "I18nString"
  //     },
  //     {
  //       "stringValue": "Base radio station",
  //       "language": "en",
  //       "__typename": "I18nString"
  //     }
  //   ],
  //   "geometryCategory": {
  //     "i18nStrings": [
  //       {
  //         "stringValue": "Estações de Rádio Base (ERBs)",
  //         "language": "pt-BR",
  //         "__typename": "I18nString"
  //       },
  //       {
  //         "stringValue": "Base radio station",
  //         "language": "en",
  //         "__typename": "I18nString"
  //       }
  //     ],
  //     "__typename": "GeometryCategory"
  //   },
  //   "__typename": "GeospatialObjectCategoryTreeLevel"
  // }
];
