import React, { Fragment } from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { useQuery } from '@apollo/react-hooks';

import LinearProgress from '@material-ui/core/LinearProgress';

import LineChart from '../../../MapBiomasLandingPage/views/Map/components/StatisticsBox/components/LineChart';

import useGenericDashboardStatistics from '../../../../hooks/useGenericDashboardStatistics';

import styles from './TerritoryHistoricalClassChart.module.scss';

import {
  GET_CLASS_TREE_DATA,
} from './query';

export default function TerritoryHistoricalClassChart({
  activeYear,
  classTreeKey,
  territoryId,
  statisticsPath,
  additionalParams = {},
  chartType = 'bar',
  legendHeight,
}) {
  const statisticsData = useGenericDashboardStatistics({
    classTreeKey,
    toYear: activeYear,
    territoryId,
    statisticsPath,
    ...additionalParams,
  });
  const { loading, data, pixelValues } = statisticsData;

  const locale = localStorage.getItem('locale') || 'pt-BR';

  const { data: classTreeData } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey
    },
  });

  const years = _.orderBy(_.map(data, 'year'));
  const classesLevelsList = _.get(classTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi');

  let parsedData = _.map(pixelValues, (pixelValue) => {
    const nodeData = _.find(classesLevelsList, { pixelValue });
    const stringList = _.get(nodeData, 'i18nStrings');
    const selectedString = _.find(stringList, { language: locale });
    const className = `${ _.join(_.get(nodeData, 'positionInTree'), '.') }. ${ _.get(selectedString, 'stringValue') }`;

    return {
      ...(nodeData || {}),
      name: className,
      data: _.map(years, (year) => {
        const yearData = _.find(data, { year });
        const pixelArea = _.find(_.get(yearData, 'areas'), { pixelId: pixelValue });
        return _.get(pixelArea, 'value', 0);
      })
    };
  });

  const historicalSeriesChartData = _.map(parsedData, (item) => {
    return {
      name: item.name,
      color: item.color,
      data: item.data,
      level: item.level,
      padding: item.level * 12,
    };
  });

  const renderHistoricalSeriesChart = () => {
    if (!historicalSeriesChartData || _.isEmpty(years)) {
      return null;
    }

    return (
      <div className={ styles.section }>
        <h2 className={ styles.secondaryTitle }><FormattedMessage id="mapbiomas.dashboard.coverage.coverage_main.charts.historical_line.title" /></h2>
        <LineChart
          showLegend
          showAxisLine={ false }
          height={ 300 }
          chartType={ chartType }
          categories={ years }
          series={ historicalSeriesChartData }
          mantissa={ 0 }
          legendHeight={ legendHeight }
        />
      </div>
    );
  };

  return (
    <Fragment>
      { loading &&
        <div className={ styles.progressWrapper }>
          <LinearProgress />
        </div>
      }
      { !loading && renderHistoricalSeriesChart() }
    </Fragment>
  );
}
