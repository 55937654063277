import React, { useRef, useEffect } from 'react';
import classnames from 'classnames';

import useGenericDashboardMap from '../../../../hooks/useGenericDashboardMap';
import useGenericBaseLayer from '../../../../hooks/useGenericBaseLayer';

import styles from './TerritoryCustomMap.module.scss';

export default function TerritoryCustomMap({
  size = 300,
  showBaseLayer = true,
  className,
  year,
  territoryId,
  classTreeKey,
  territoryBoundingBox,
  mapPath,
  additionalParams = {},
  color = 'none',
}) {
  const bboxStr = territoryBoundingBox ? `${ territoryBoundingBox[0][1] },${ territoryBoundingBox[0][0] },${ territoryBoundingBox[1][1] },${ territoryBoundingBox[1][0] }` : ''; 
  const mapData = useGenericDashboardMap({
    classTreeKey,
    year,
    territoryId,
    mapPath,
    bbox: bboxStr,
    bboxSize: size,
    ...additionalParams
  });
  const { loading, data: mapUrl, pixelValues } = mapData;

  const layerData = useGenericBaseLayer({
    pathKey: 'layer',
    layer: 'municipality',
    bbox: bboxStr,
    bboxSize: size,
  });
  const { url: baseLayerUrl } = layerData;

  let mapRef = useRef();

  useEffect(() => {
    if (mapRef && mapRef.current && territoryBoundingBox) {
      mapRef.current.leafletElement.fitBounds(territoryBoundingBox);
    }
  }, [territoryBoundingBox]);

  const renderMainLayer = () => {
    if (!loading && mapUrl) {
      return (
        <>
          { showBaseLayer &&
            <img
              src={ baseLayerUrl }
              alt="imagem dos municípios"
            />
          }
          <img
            src={ mapUrl }
            alt="imagem do território"
          />
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <div
      className={ classnames(styles.box, className, {
        [styles.boxBlack]: color === 'black'
      }) }
      style={{ width: size + 'px', height: size + 'px' }}
    >
      { renderMainLayer() }
    </div>
  );
};
