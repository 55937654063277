import React, { Fragment } from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl';

import LinearProgress from '@material-ui/core/LinearProgress';

import PieChart from '../../../MapBiomasLandingPage/views/Map/components/StatisticsBox/components/PieChart';

import useFormatMessage from '../../../../hooks/useFormatMessage';
import useGenericDashboardStatistics from '../../../../hooks/useGenericDashboardStatistics';

import {
  GET_CLASS_TREE_DATA,
} from './query';

import styles from './TerritoryPieClassChart.module.scss';

export default function TerritoryPieClassChart({
  activeYear = 2023,
  classTreeKey = 'agriculture_agricultural_use_main',
  territoryId = 10001,
  statisticsPath,
  additionalParams = {},
}) {
  const statisticsData = useGenericDashboardStatistics({
    classTreeKey,
    fromYear: activeYear,
    toYear: activeYear,
    territoryId,
    statisticsPath,
    ...additionalParams,
  });
  const { loading, data, pixelValues } = statisticsData;

  const locale = localStorage.getItem('locale') || 'pt-BR';
  const formatMessage = useFormatMessage();

  const { data: classTreeData } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey
    },
  });

  const years = _.orderBy(_.map(data, 'year'));
  const activeYearIndex = _.findIndex(years, (year) => Array.isArray(activeYear) ? year === activeYear[1] : year === activeYear);
  const classesLevelsList = _.get(classTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi');

  let parsedData = _.map(pixelValues, (pixelValue) => {
    const nodeData = _.find(classesLevelsList, { pixelValue });
    const stringList = _.get(nodeData, 'i18nStrings');
    const selectedString = _.find(stringList, { language: locale });
    const className = `${ _.join(_.get(nodeData, 'positionInTree'), '.') }. ${ _.get(selectedString, 'stringValue') }`;

    return {
      ...(nodeData || {}),
      name: className,
      data: _.map(years, (year) => {
        const yearData = _.find(data, { year });
        const pixelArea = _.find(_.get(yearData, 'areas'), { pixelId: pixelValue });
        return _.get(pixelArea, 'value', 0);
      })
    };
  });

  const classViewChartData = _(parsedData)
    .map((item) => {
      const itemValue = _.get(item, `data[${ activeYearIndex }]`);

      return {
        name: _.get(item, 'name'),
        value: itemValue,
        itemStyle: {
          color: _.get(item, 'color')
        },
      };
    })
    .value();

  const renderClassViewChart = () => {
    if (!classViewChartData) {
      return null;
    }

    return (
      <div>
        <h2 className={ styles.secondaryTitle }><FormattedMessage id="mapbiomas.dashboard.coverage.coverage_main.charts.classes_pie.title" values={{ year: activeYear }} /></h2>
        <PieChart
          serie={{
            title: formatMessage('mapbiomas.dashboard.coverage.coverage_main.charts.classes_pie.unit'),
            data: classViewChartData
          }}
          mantissa={ 0 }
        />
      </div>
    );
  };

  return (
    <Fragment>
      { loading &&
        <div>
          <LinearProgress />
        </div>
      }
      { !loading && renderClassViewChart() }
    </Fragment>
  );
}
