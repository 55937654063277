import React, { Fragment } from 'react';
import ReactEcharts from 'echarts-for-react';
import _ from 'lodash';
import numbro from 'numbro';
import { useQuery } from '@apollo/react-hooks';

import LinearProgress from '@material-ui/core/LinearProgress';

import useGenericDashboardStatistics from '../../../../hooks/useGenericDashboardStatistics';

import styles from './TerritoryBarComparison.module.scss';

import {
  GET_CLASS_TREE_DATA,
} from './query';

function tooltipFormatter(params) {
  const colorSpan = (color) => `<br /><span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${ color }"></span>`;
  let str = `<span><b>${ params[0].axisValue }</b></span>`;

  params.forEach((item) => {
    const value = _.isObject(item.data) ? item.data.value : item.data;

    if (value) {
      const percentageLabel = numbro(item.data.percentage).format({
        thousandSeparated: true,
        mantissa: 1,
      });
      const valueLabel = numbro(value).format({
        thousandSeparated: true,
        mantissa: 0,
      });
      const paramValue = `<span>${ colorSpan(item.color) } <b>${ item.seriesName }: ${ percentageLabel }%</b> (${ valueLabel } ha)</span>`;

      str += paramValue;
    }
  });

  return str;
}

const formatNumber = (value, mantissa = 0) => {
  if (value === null || value === undefined) {
    return '-';
  } else if (value === 0) {
    return 0;
  } else {
    return numbro(value).format({
      thousandSeparated: true,
      mantissa
    });
  }
};

export default function TerritoryBarComparison({
  activeYear,
  classTreeKey,
  territoryId,
  statisticsPath,
  additionalParams = {},
  filteredPixelValues,
}) {
  const statisticsData = useGenericDashboardStatistics({
    classTreeKey,
    toYear: activeYear,
    territoryId,
    statisticsPath,
    filteredPixelValues,
    ...additionalParams,
  });
  const { loading, data, pixelValues } = statisticsData;

  const locale = localStorage.getItem('locale') || 'pt-BR';

  const { data: classTreeData, loading: loadingClassTreeData } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey
    },
  });

  const isLoading = loading || loadingClassTreeData;

  const years = _.orderBy(_.map(data, 'year'));
  const firstYear = _.first(years);
  const lastYear = _.last(years);
  const comparisonYears = [lastYear, firstYear];
  const classesLevelsList = _.get(classTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi');

  let parsedData = _.map(filteredPixelValues || pixelValues, (pixelValue) => {
    const nodeData = _.find(classesLevelsList, { pixelValue });
    const stringList = _.get(nodeData, 'i18nStrings');
    const selectedString = _.find(stringList, { language: locale });
    const className = _.get(selectedString, 'stringValue');
    const yearData = _.map(comparisonYears, (year) => {
      const yearData = _.find(data, { year });
      const pixelArea = _.find(_.get(yearData, 'areas'), { pixelId: pixelValue });
      const value = _.get(pixelArea, 'value', 0);
      const areaTotal = _.get(yearData, 'areaTotal');

      return {
        value,
        percentage: (value / areaTotal) * 100
      };
    });
    const hasSmallValue = _.min(_.map(yearData, 'percentage')) < 10;
    
    return {
      ...(nodeData || {}),
      name: className,
      data: yearData,
      type: 'bar',
      stack: 'year',
      emphasis: {
        focus: 'series'
      },
      label: {
        show: true,
        formatter: (params) => {
          return `${ formatNumber(params.data.percentage, 1) }%  {name|${ formatNumber(params.data.value) } ha}`;
        },
        fontSize: 16,
        fontWeight: 'bold',
        rich: {
          name: {}
        },
        position: hasSmallValue ? 'insideLeft' : 'inside'
      },
    };
  });

  const renderInfo = () => {
    if (!parsedData) {
      return null;
    }

    return (
      <ReactEcharts
        style={{ height: 230 }}
        option={{
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'none'
            },
            formatter: tooltipFormatter,
          },
          grid: {
            height: 200,
            bottom: 0,
            top: 0,
            left: '5%',
            right: 0,
            containLabel: true
          },
          xAxis: {
            type: 'value',
            splitLine: {
              show: false
            },
            axisLabel: {
              show: false,
            },
          },
          yAxis: {
            type: 'category',
            data: comparisonYears,
            axisLabel: {
              color: '#333',
              fontSize: 16,
              fontWeight: 'bold'
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            }
          },
          series: _.reverse(parsedData),
          legend: {
            show: true,
            left: 'center',
            bottom: 0,
          },
        }}
        notMerge={ true }
      />
    );
  };

  return (
    <Fragment>
      { isLoading &&
        <div className={ styles.progressWrapper }>
          <LinearProgress />
        </div>
      }
      { !isLoading && renderInfo() }
    </Fragment>
  );
}
