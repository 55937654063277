export default {
  coverage: {
    activeModule: 'coverage',
    activeModuleContent: 'coverage:coverage_main',
    activeYear: 2023,
    timelineSpecificValues: null,
    timelineLimitsRange: [1985, 2023],
    mapboxMode: false,
  },
  temporal_analysis: {
    activeModule: 'temporal_analysis',
    activeModuleContent:
      'temporal_analysis:temporal_analysis_number_of_classes',
    activeYear: [1985, 2023],
    timelineSpecificValues: null,
    timelineLimitsRange: [1985, 2023],
    mapboxMode: false,
  },
  coverage_quality: {
    activeModule: 'coverage_quality',
    activeModuleContent: 'coverage_quality:coverage_quality_main',
    activeYear: 2022,
    timelineSpecificValues: null,
    timelineLimitsRange: [1985, 2022],
    mapboxMode: false,
  },
  pasture: {
    activeModule: 'pasture',
    activeModuleContent: 'pasture:pasture_age',
    activeYear: [1985, 2023],
    timelineSpecificValues: null,
    timelineLimitsRange: [1985, 2023],
    mapboxMode: false,
  },
  agriculture: {
    activeModule: 'agriculture',
    activeModuleContent: 'agriculture:agriculture_agricultural_use',
    activeYear: [1985, 2023],
    timelineSpecificValues: null,
    timelineLimitsRange: [1985, 2023],
    mapboxMode: false,
  },
  deforestation: {
    activeModule: 'deforestation',
    activeModuleContent: 'deforestation:deforestation_annual',
    activeYear: [1986, 2023],
    timelineSpecificValues: null,
    timelineLimitsRange: [1986, 2023],
    mapboxMode: false,
  },
  regeneration: {
    activeModule: 'regeneration',
    activeModuleContent: 'regeneration:regeneration_annual',
    activeYear: [1986, 2023],
    timelineSpecificValues: null,
    timelineLimitsRange: [1986, 2023],
    mapboxMode: false,
  },
  infrastructure: {
    activeModule: 'infrastructure',
    activeModuleContent: 'infrastructure:infrastructure_main',
    activeYear: 2020,
    timelineSpecificValues: null,
    timelineLimitsRange: [1985, 2020],
    mapboxMode: false,
  },
  fire: {
    activeModule: 'fire',
    activeModuleContent: 'fire:fire_accumulated',
    activeYear: [1985, 2022],
    timelineSpecificValues: null,
    timelineLimitsRange: [1985, 2022],
    mapboxMode: false,
  },
  mining: {
    activeModule: 'mining',
    activeModuleContent: 'mining:mining_main',
    activeYear: [1985, 2023],
    timelineSpecificValues: null,
    timelineLimitsRange: [1985, 2023],
    mapboxMode: false,
  },
  soil: {
    activeModule: 'soil',
    activeModuleContent: 'soil:organic_carbon_stock',
    activeYear: 2023,
    timelineSpecificValues: null,
    timelineLimitsRange: [1985, 2023],
    mapboxMode: false,
  },
  sentinel: {
    activeModule: 'sentinel',
    activeModuleContent: 'sentinel:sentinel_coverage',
    activeYear: 2022,
    timelineSpecificValues: null,
    timelineLimitsRange: [2016, 2022],
    mapboxMode: false,
  },
  degradation: {
    activeModule: 'degradation',
    activeModuleContent: 'degradation:degradation_fragmentation',
    activeYear: 2022,
    timelineSpecificValues: null,
    timelineLimitsRange: [1986, 2022],
    mapboxMode: false,
  },
  urban: {
    activeModule: 'urban',
    activeModuleContent: 'urban:urban_coverage',
    activeYear: 2023,
    timelineSpecificValues: null,
    timelineLimitsRange: [1985, 2023],
    mapboxMode: false,
  },
};
